import { useEffect, forwardRef, useState } from 'react';
import { Form, Collapse, Input, Select } from 'antd';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import Icon from '@ant-design/icons';

import { formatOptions } from 'utils';
import {
  cleanEmptyString,
  isUserAuthorized,
  trimObjectStrings
} from 'utils/helpers';
import { getCurrentUserHubInfo } from 'utils/hubs';
import { fetchCities, fetchZones, getFmCodes } from 'services/cities';
import { fetchStars } from 'services/live-ops-priorities';
import { DATE_FORMAT, TODAY } from 'constants/date-picker';
import {
  PICKUPS_DATE_RANGE_FILTERS_KEYS,
  PICKUP_EXCEPTION_VALIDATION,
  PICKUPS_MULTIPLE_VALUES_FILTERS,
  PICKUP_TYPES_OPTIONS,
  RepetitionTypes,
  TypeAssignHubs,
  stateTypes
} from 'constants/pickups';
import aclMatrix from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';

import FilterFooter from './components/FilterFooter/FilterFooter';
import FilterHeader from './components/FilterHeader/FilterHeader';
import SearchDateRangePicker from 'containers/Deliveries/DeliveriesSearch/components/SearchDateRangePicker/SearchDateRangePicker';
import BusinessSelector from 'components/BRSelectors/BusinessSelector/BusinessSelector';
import SearchCheckbox from 'containers/Deliveries/DeliveriesSearch/components/SearchCheckbox/SearchCheckbox';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as DropDownIcon } from 'assets/bosta-icons/Dropdown.svg';
import { ReactComponent as CreatedByStarIcon } from 'assets/bosta-icons/created-by-star.svg';
import { ReactComponent as FirstBusinessPickupIcon } from 'assets/bosta-icons/first-business-pickup.svg';

import './PickupsSearch.less';

const PickupsSearch = forwardRef(({ handleOnSubmit, hubs }, ref) => {
  const [cities, setCities] = useState([]);
  const [stars, setStars] = useState([]);
  const [areas, setAreas] = useState([]);
  const [fmCodes, setFmCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const intl = useIntl();

  const [form] = Form.useForm();
  const cityId = Form.useWatch('cityId', form);

  const currentUserHubId = getCurrentUserHubInfo()?._id;

  useEffect(async () => {
    setIsLoading(true);
    await Promise.all([getCities(), fetchFmCodes(), getStars()]);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (cityId) {
      getAreas();
    } else {
      setAreas([]);
    }
  }, [cityId]);

  const getCities = async () => {
    try {
      const data = await fetchCities();
      setCities(data);
    } catch (error) {
      notify(error.message);
    }
  };

  const fetchFmCodes = async () => {
    try {
      const data = (await getFmCodes()) || [];
      const formattedFMCodes = data.map((fmCode) => ({
        label: fmCode,
        value: fmCode
      }));
      setFmCodes(formattedFMCodes);
    } catch (error) {
      notify(error.message);
    }
  };

  const getStars = async () => {
    try {
      const { message } = await fetchStars();

      setStars(
        formatOptions(
          '_id',
          'profile.firstName,profile.lastName',
          message?.stars || []
        )
      );
    } catch (error) {
      notify(error.message);
    }
  };

  const getAreas = async () => {
    setIsLoading(true);
    try {
      const data = await fetchZones(cityId);
      if (data?.length) {
        setAreas(data);
      }
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const onFinish = (values) => {
    values.starInitiatedAction = values.starInitiatedAction || undefined;
    values.isBusinessFirstPickup = values.isBusinessFirstPickup || undefined;
    PICKUPS_MULTIPLE_VALUES_FILTERS.forEach((key) => {
      if (values[key]) {
        values[key] = values[key].join(',');
      }
    });

    const cleanedValues = cleanEmptyString(values);

    Object.keys(cleanedValues).forEach((key) => {
      if (PICKUPS_DATE_RANGE_FILTERS_KEYS.includes(key)) {
        const dateStartKey = `${key}Start`;
        const dateEndKey = `${key}End`;

        cleanedValues[dateStartKey] = dayjs(values[key][0]).format(DATE_FORMAT);

        cleanedValues[dateEndKey] = dayjs(values[key][1]).format(DATE_FORMAT);

        delete cleanedValues[key];
      }
    });

    handleOnSubmit(trimObjectStrings(cleanedValues));
  };

  const handleSubmitFilters = (e) => {
    e?.stopPropagation();
    ref.current.submit();
  };

  const handleResetFilters = (e) => {
    e?.stopPropagation();
    ref.current.resetFields();
    handleOnSubmit({});
  };

  const handleExpandToggle = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <Collapse
      className="br-filters-search__container"
      ghost
      defaultActiveKey={['0']}
      expandIcon={({ isActive }) => {
        setIsExpanded(isActive);
        return (
          <Icon
            component={DropDownIcon}
            rotate={isActive ? 0 : -90}
            className="ant-icon-xl"
          />
        );
      }}
    >
      <Collapse.Panel
        collapsible={'disabled'}
        showArrow={false}
        header={<FilterHeader />}
      >
        <Form
          onFinish={onFinish}
          ref={ref}
          form={form}
          initialValues={
            currentUserHubId
              ? { hubId: [currentUserHubId], scheduledAt: TODAY }
              : { scheduledAt: TODAY }
          }
        >
          <div className="br-filters-search__row">
            <SearchDateRangePicker
              name="scheduledAt"
              label={intl.formatMessage({
                id: 'deliveries.search_filters.scheduledAt'
              })}
            />
            <Form.Item
              name="hubId"
              label={intl.formatMessage({
                id: 'stars.create_edit_star.form_labels.hub'
              })}
            >
              <Select
                allowClear
                showSearch
                showArrow
                mode="multiple"
                options={hubs}
                optionFilterProp="name"
                fieldNames={{ label: 'name', value: '_id' }}
                placeholder={intl.formatMessage({
                  id: 'common.select'
                })}
                getPopupContainer={(trigger) => trigger.parentElement}
              />
            </Form.Item>
            <Form.Item
              name="pickupRequestId"
              label={intl.formatMessage({
                id: 'pickups.pickup_request.search.pickup_request_id'
              })}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'pickups.pickup_request.search.pickup_request_id'
                })}
              />
            </Form.Item>

            {isUserAuthorized(aclMatrix.BUSINESSES, [
              ACL_MATRIX.BUSINESSES_LIST
            ]) && (
              <Form.Item
                name="businessId"
                label={intl.formatMessage({
                  id: 'pickups.pickup_request.search.business'
                })}
              >
                <BusinessSelector
                  allowClear
                  name="businessId"
                  placeholder={intl.formatMessage({
                    id: 'common.select'
                  })}
                  getPopupContainer={(trigger) => trigger.parentElement}
                />
              </Form.Item>
            )}
          </div>
          {isExpanded && (
            <>
              <div className="br-filters-search__row">
                <Form.Item
                  name="state"
                  label={intl.formatMessage({
                    id: 'pickups.pickup_request.search.state'
                  })}
                >
                  <Select
                    allowClear
                    mode="multiple"
                    showArrow
                    options={stateTypes}
                    placeholder={intl.formatMessage({ id: 'common.select' })}
                    getPopupContainer={(trigger) => trigger.parentElement}
                  />
                </Form.Item>
                <Form.Item
                  name="cityId"
                  label={intl.formatMessage({
                    id: 'businesses.search_filters.city'
                  })}
                >
                  <Select
                    allowClear
                    showSearch
                    options={cities}
                    optionFilterProp="name"
                    fieldNames={{ label: 'name', value: '_id' }}
                    placeholder={intl.formatMessage({
                      id: 'common.select'
                    })}
                    getPopupContainer={(trigger) => trigger.parentElement}
                  />
                </Form.Item>

                <Form.Item
                  name="areaId"
                  label={intl.formatMessage({
                    id: 'location_form_labels.area'
                  })}
                >
                  <Select
                    allowClear
                    showSearch
                    options={areas}
                    optionFilterProp="name"
                    fieldNames={{ label: 'name', value: '_id' }}
                    placeholder={intl.formatMessage({
                      id: 'common.select'
                    })}
                    disabled={!cityId || isLoading}
                    getPopupContainer={(trigger) => trigger.parentElement}
                  />
                </Form.Item>

                <Form.Item
                  name="fmCodes"
                  label={intl.formatMessage({
                    id: 'pickups.pickup_request.search.fm_code'
                  })}
                >
                  <Select
                    allowClear
                    showSearch
                    showArrow
                    mode="multiple"
                    options={fmCodes}
                    placeholder={intl.formatMessage({ id: 'common.select' })}
                    getPopupContainer={(trigger) => trigger.parentElement}
                  />
                </Form.Item>
              </div>

              <div className="br-filters-search__row">
                <Form.Item
                  name="starId"
                  label={intl.formatMessage({
                    id: 'pickups.pickup_request.search.star'
                  })}
                >
                  <Select
                    allowClear
                    showSearch
                    options={stars}
                    optionFilterProp="label"
                    placeholder={intl.formatMessage({
                      id: 'common.select'
                    })}
                    getPopupContainer={(trigger) => trigger.parentElement}
                  />
                </Form.Item>
                <Form.Item
                  name="isHubAutoAssigned"
                  label={intl.formatMessage({
                    id: 'pickups.pickup_request.search.assign_type'
                  })}
                >
                  <Select
                    allowClear
                    options={TypeAssignHubs}
                    placeholder={intl.formatMessage({ id: 'common.select' })}
                    getPopupContainer={(trigger) => trigger.parentElement}
                  />
                </Form.Item>
                <Form.Item
                  name="type"
                  label={intl.formatMessage({
                    id: 'pickups.pickup_request.search.pickup_request_type'
                  })}
                >
                  <Select
                    allowClear
                    options={PICKUP_TYPES_OPTIONS}
                    placeholder={intl.formatMessage({ id: 'common.select' })}
                    getPopupContainer={(trigger) => trigger.parentElement}
                  />
                </Form.Item>
                <Form.Item
                  name="whatsAppFakeException"
                  label={intl.formatMessage({
                    id: 'pickups.pickup_request.search.exception_validation'
                  })}
                >
                  <Select
                    options={PICKUP_EXCEPTION_VALIDATION}
                    allowClear
                    placeholder={intl.formatMessage({ id: 'common.select' })}
                  />
                </Form.Item>
              </div>

              <div className="br-filters-search__row">
                <SearchDateRangePicker
                  name="createdAt"
                  label={intl.formatMessage({
                    id: 'deliveries.search_filters.createdAt'
                  })}
                />

                <SearchDateRangePicker
                  name="assignedAt"
                  label={intl.formatMessage({
                    id: 'pickups.pickup_request.table_columns.assigned_at'
                  })}
                />
                <SearchDateRangePicker
                  name="attemptDate"
                  label={intl.formatMessage({
                    id: 'pickups.pickup_request.table_columns.attempt_at'
                  })}
                />

                <SearchDateRangePicker
                  name="pickedUpAt"
                  label={intl.formatMessage({
                    id: 'deliveries.search_filters.pickedUpAt'
                  })}
                />
              </div>
              <div className="br-filters-search__row">
                <SearchCheckbox
                  name="isBusinessFirstPickup"
                  label={intl.formatMessage({
                    id: 'pickups.pickup_request.search.first_business_pickup'
                  })}
                  extraContent={
                    <div className="br-pickups__icon">
                      <FirstBusinessPickupIcon className="br-pickups__icon-first-business-pickup" />
                    </div>
                  }
                />
                <SearchCheckbox
                  name="starInitiatedAction"
                  label={intl.formatMessage({
                    id: 'pickups.pickup_request.search.created_by_star'
                  })}
                  extraContent={
                    <div className="br-pickups__icon">
                      <CreatedByStarIcon className="br-pickups__icon-created-by-star" />
                    </div>
                  }
                />
                <Form.Item
                  name="repeatedType"
                  label={intl.formatMessage({
                    id: 'pickups.pickup_request.search.repetition_type'
                  })}
                >
                  <Select
                    allowClear
                    options={RepetitionTypes}
                    placeholder={intl.formatMessage({ id: 'common.select' })}
                    getPopupContainer={(trigger) => trigger.parentElement}
                  />
                </Form.Item>
              </div>
            </>
          )}
        </Form>
        <FilterFooter
          isExpanded={isExpanded}
          handleSubmitFilters={handleSubmitFilters}
          handleResetFilters={handleResetFilters}
          handleExpandToggle={handleExpandToggle}
        />
      </Collapse.Panel>
    </Collapse>
  );
});

export default PickupsSearch;
