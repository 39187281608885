import { Select } from 'antd';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { getInsurancePlans, updateInsurancePlan } from 'services/pricing';

import { notify } from 'components/Notify/Notify';

import './InsurancePlanSelector.less';

const InsurancePlanSelector = ({ _id: businessId, insurancePlan }) => {
  const [selectedInsurancePlanId, setSelectedInsurancePlanId] = useState(
    insurancePlan?.id
  );
  const [insurnacePlansOptions, setInsurancePlansOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const intl = useIntl();

  const fetchInsurancePlans = async () => {
    setIsLoading(true);

    try {
      const {
        data: { insurancePlans = [] }
      } = await getInsurancePlans();

      const formattedPlans = insurancePlans.map((plan) => ({
        value: plan._id,
        label: plan.name
      }));
      setInsurancePlansOptions(formattedPlans);
    } catch (error) {
      notify(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInsurancePlanChange = async (insurancePlanId) => {
    setIsLoading(true);
    setSelectedInsurancePlanId(insurancePlanId);

    try {
      await updateInsurancePlan({ businessId, payload: { insurancePlanId } });
      notify(
        intl.formatMessage({
          id: 'business_details.business_info.insurnace_plan_update_success'
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
      setSelectedInsurancePlanId(insurancePlan?.id);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (businessId) {
      fetchInsurancePlans();
    }
  }, [businessId]);

  return (
    <Select
      className="br-insurance-plan__selector"
      options={insurnacePlansOptions}
      disabled={isLoading}
      onChange={handleInsurancePlanChange}
      value={!!insurnacePlansOptions?.length && selectedInsurancePlanId}
    />
  );
};

export default InsurancePlanSelector;
