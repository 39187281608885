import { injectIntl } from 'react-intl';

import { isSaudi } from 'constants/helper';
import { getCurrency } from 'common/countries/countries-mapping';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import { ReactComponent as MenuIcon } from 'assets/bosta-icons/Menu.svg';

import './BusinessInfoSection.less';

const userInfo = JSON.parse(localStorage.getItem('userInfo'));
const ADMIN_NAME = `${userInfo?.profile?.firstName || ''} ${
  userInfo?.profile?.lastName || ''
}`;
const ADMIN_EMAIL = userInfo?.emails?.length ? userInfo.emails[0]?.address : '';
const BusinessInfoSection = ({
  intl,
  delivery: { sender, insurancePlanInfo },
  totalAmount,
  amountLoading,
  businessDetails,
  selectedDeliveries
}) => {
  const totalInvoiceValue = selectedDeliveries.reduce(
    (acc, delivery) => acc + delivery?.goodsInfo?.amount || 0,
    0
  );

  const insurancePlanName = () => {
    if (selectedDeliveries?.length === 1) {
      const deliveryInsurance = selectedDeliveries[0]?.insurancePlanInfo;
      if (deliveryInsurance?.isInsuranceNotApplied) {
        return intl.formatMessage({
          id: `orders.shipment_details.shipment_details_summary.table_cells.shipment_info_table.not_applied`
        });
      }
      return deliveryInsurance?.name;
    }
    return intl.formatMessage({ id: 'common.empty_field' });
  };

  return (
    <div className="br-business-info-content-container">
      <div className="br-compensation__form-section-header">
        <MenuIcon /> Request details
      </div>
      <div className="br-compensation__business-info-rows">
        <div className="br-business-info-container">
          <div className="br-business-info-content">
            <div className="br-business-info-content-text br-business-info-content-text-label">
              <span>
                {intl.formatMessage({
                  id: 'request_compensation_modal.item_value'
                })}
              </span>
              <span>
                {intl.formatMessage({
                  id: 'request_compensation_modal.order_insurance'
                })}
              </span>
            </div>
            <div className="br-business-info-content-text br-business-info-content-value">
              <span>
                {totalInvoiceValue
                  ? getCurrency(totalInvoiceValue).localized
                  : intl.formatMessage({ id: 'common.empty_field' })}
              </span>
              <span>{insurancePlanName()}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="br-compensation__business-info-rows">
        <div className="br-business-info-container">
          <span className="br-business-info__header">
            {intl.formatMessage({
              id: 'request_compensation_modal.business_info_title'
            })}
          </span>
          <div className="br-business-info-content">
            <div className="br-business-info-content-text br-business-info-content-text-label">
              <span>
                {intl.formatMessage({
                  id: 'request_compensation_modal.business_name'
                })}
              </span>
              <span>
                {intl.formatMessage({
                  id: 'request_compensation_modal.business_id'
                })}
              </span>
              <span>
                {intl.formatMessage({
                  id: 'request_compensation_modal.business_insurance'
                })}
              </span>
            </div>
            <div className="br-business-info-content-text br-business-info-content-value">
              <span>{sender?.name}</span>
              <span>{sender?._id}</span>
              <span>
                {businessDetails?.insurancePlan?.name ||
                  intl.formatMessage({ id: 'common.empty_field' })}
              </span>
            </div>
          </div>
        </div>
        <div className="br-business-info-container submitter-info-section">
          <span className="br-business-info__header">
            {intl.formatMessage({
              id: 'request_compensation_modal.submitter_info_title'
            })}
          </span>
          <div className="br-business-info-content">
            <div className="br-business-info-content-text br-business-info-content-text-label">
              <span>
                {intl.formatMessage({
                  id: 'request_compensation_modal.submitted_by'
                })}
              </span>
              <span className="br-business-info-email-text">
                {intl.formatMessage({
                  id: 'request_compensation_modal.email'
                })}
              </span>
            </div>
            <div className="br-business-info-content-text br-business-info-content-value">
              <span>{ADMIN_NAME}</span>
              <span className="br-business-info-email-text">{ADMIN_EMAIL}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="br-business-info__amount-container">
        <span className="br-business-info__header">
          {intl.formatMessage({
            id: 'request_compensation_modal.total_amount'
          })}
        </span>
        <LoadingWrapper loading={amountLoading}>
          <span className="br-amount-text">
            {isSaudi() ? 'SAR' : 'EGP'}{' '}
            {Math.round(totalAmount * 100) / 100 ?? 0}
          </span>
        </LoadingWrapper>
      </div>
    </div>
  );
};

export default injectIntl(BusinessInfoSection);
