import dayjs from 'dayjs';
import { fmt } from 'IntlWrapper/IntlWrapper';
import XlsxPopulate from 'xlsx-populate';
import { omitBy } from 'lodash';

import {
  ADDRESS_UPDATED,
  CANCEL_ORDER,
  RESCHEDULED,
  RESCHEDULED_TOMORROW,
  RESTORED_DELIVERY,
  UPDATED_DELIVERY_DETAILS
} from 'constants/action-center';
import { openModal } from 'utils/modal';
import { isDefaultBostaCourier } from 'utils/helpers';
import aclMatrix, { VENDOR_MANAGER } from 'common/aclMatrix';
import {
  DELIVERIES_SCAN_TYPES,
  FIRST_MILE_HUB,
  MAP_DELIVERY_TYPES,
  OFD_ORDERS_CODES, PACKAGE_SIZES,
  RECEIVED_AT_WAREHOUSE_CODE, REJECTED_RETURN,
  SOHO_PICKUP,
  SORTING_HUB
} from 'constants/hubs';
import { removeScannedDeliveries } from 'services/hubs';

import { notify } from 'components/Notify/Notify';
import BRScanningPopup from 'components/BRScanningPopup/BRScanningPopup';
import {INITIAL_STATES, RETURN_REJECTED_EXCEPTION_CODES} from "../constants/shipments";

export const endDebriefingConditions = ({ starPickups, starDeliveries }) => {
  if (
    starPickups.length &&
    starPickups.some((el) => el.state === 'Picked up')
  ) {
    notify(
      'You have to receive all exception packages and pickup packages before ending the debrief.'
    );
    return false;
  }
  if (
    starDeliveries.length &&
    starDeliveries.some((el) => el.state.code === 45 || el.state.code === 46)
  ) {
    notify(
      'You have packages that are not confirmed yet, Confirm all deliveries before ending the debrief'
    );
    return false;
  }
  return true;
};

const prioritizeActions = (actions) => {
  if (actions.length) {
    if (actions.includes(CANCEL_ORDER)) {
      return CANCEL_ORDER;
    } else if (actions.includes(ADDRESS_UPDATED)) {
      return ADDRESS_UPDATED;
    } else if (actions.includes(UPDATED_DELIVERY_DETAILS)) {
      return UPDATED_DELIVERY_DETAILS;
    } else if (actions.includes(RESCHEDULED_TOMORROW)) {
      return RESCHEDULED_TOMORROW;
    } else if (actions.includes(RESCHEDULED)) {
      return RESCHEDULED;
    }
  }
};

export const getDeliveryPendingActions = (deliveries) => {
  if (deliveries?.actionItems) {
    const pendingActions = deliveries?.actionItems.filter(
      (item) => !item.isApplied
    );

    if (pendingActions) {
      const allPendingActionsType = pendingActions.map((item) => {
        if (item.action === UPDATED_DELIVERY_DETAILS) {
          return item.actionDetails?.dropOffAddress
            ? ADDRESS_UPDATED
            : UPDATED_DELIVERY_DETAILS;
        } else if (
          item.action === RESCHEDULED &&
          dayjs(item.scheduledAt).isSameOrAfter(dayjs().startOf('day'))
        ) {
          const current = dayjs().startOf('day');
          const rescheduleDate = dayjs(item.scheduledAt);
          return rescheduleDate.diff(current, 'days') > 1
            ? RESCHEDULED
            : RESCHEDULED_TOMORROW;
        } else if (item.action === CANCEL_ORDER) {
          return CANCEL_ORDER;
        }
      });
      return prioritizeActions(allPendingActionsType);
    }
  }
};

export const showNotification = (action, delivery) => {
  if (!action) {
    return;
  }

  let actionTitle = '';
  let actionData = '';
  let bgClassName = '';

  switch (action) {
    case ADDRESS_UPDATED:
      actionTitle = fmt({ id: 'action_center.address_updated' });
      actionData = fmt({
        id: 'action_center.order_details.action_notes.print_awb'
      });
      bgClassName = 'bg-blue';
      break;

    case UPDATED_DELIVERY_DETAILS:
      actionTitle = fmt({ id: 'action_center.order_details_updated' });
      actionData = fmt({
        id: 'action_center.order_details.action_notes.print_awb'
      });
      bgClassName = 'bg-blue';
      break;

    case RESCHEDULED:
      actionTitle = fmt({ id: 'orders.order_new_statues.rescheduled' });
      actionData = dayjs(delivery.scheduledAt).format('dddd, DD MMM YYYY');
      bgClassName = 'bg-green';
      break;

    case RESCHEDULED_TOMORROW:
      actionTitle = fmt({ id: 'hubs.packages_debrief.tomorrow' });
      bgClassName = 'bg-gray';
      break;

    case CANCEL_ORDER:
      actionTitle = fmt({ id: 'action_center.customer_request_to_cancel' });
      bgClassName = 'bg-red';
      break;

    case RESTORED_DELIVERY:
      actionTitle = fmt({ id: 'action_center.deleted_order' });
      actionData = fmt({ id: 'action_center.make_it_rto' });
      bgClassName = 'bg-red';
      break;

    default:
      break;
  }

  openModal(BRScanningPopup, {
    actionTitle,
    actionData,
    bgClassName
  });
};

export const getCurrentUserHubInfo = () => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  return userInfo?.warehouseInfo;
};

export const getVendorHubId = () => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  return (
    !isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) &&
    userInfo?.vendor?.hubId
  );
};

const BULK_UPLOAD_DEBRIEF_CASH_SHEET_COLUMNS = [
  'Star ID',
  'Deposit Type',
  'Receipt No.',
  'Receipt Date',
  'Amount'
];
const getJsDateFromExcel = (excelDate) => {
  // excel file parse any date and send it as seconds from 1990
  const secondsInDay = 24 * 60 * 60;
  const missingLeapYearDay = secondsInDay * 1000;
  const magincNumberOfDays = 25567 + 2;
  if (!Number(excelDate)) {
    alert('wrong input format');
  }

  const delta = excelDate - magincNumberOfDays;
  const parsed = delta * missingLeapYearDay;
  const date = new Date(parsed);

  return date;
};

const constructBulkUploadDebreifCashPayload = (row) => {
  return {
    starId: row[0]?.toString().trim(),
    depositType: row[1]?.toString().trim(),
    receiptNo: row[2]?.toString().trim(),
    receiptDate: row[3]
      ? dayjs(getJsDateFromExcel(row[3])).format('YYYY-MM-DD')
      : '',
    amount: row[4]?.toString().trim()
  };
};

export const convertExcelBulkDebriefCashToJSON = (file) => {
  return new Promise((resolve, reject) => {
    XlsxPopulate.fromDataAsync(file).then((workbook) => {
      try {
        const sheet = workbook.sheet('debriefCash');

        const sheetAdjusment = sheet.usedRange().value().slice(1);
        const sheetWithoutHeaders = sheet.usedRange().value();

        if (
          JSON.stringify(sheetWithoutHeaders[0]) !==
          JSON.stringify(BULK_UPLOAD_DEBRIEF_CASH_SHEET_COLUMNS)
        ) {
          reject({
            message: 'Unsupported Template!'
          });
        }

        const adjusments = sheetAdjusment
          .filter((row) => Object.keys(omitBy(row, (item) => !item)).length)
          .map((row) =>
            row.map((cell) =>
              cell instanceof XlsxPopulate.RichText
                ? cell.text().replace(/undefined/g, '')
                : cell
            )
          )
          .map(constructBulkUploadDebreifCashPayload);
        resolve(adjusments);
      } catch (error) {
        if (error.message.includes('usedRange')) {
          error.message = 'Wrong template';
        }
        reject(error);
      }
    });
  });
};

export const isInvalidMultiPackageScan = ({ delivery, packageNumber }) => {
  const { multiPackages, scannedPackages = [] } = delivery || {};

  if (multiPackages) {
    return scannedPackages.includes(packageNumber);
  }

  return true;
};

export const generateCounterOptions = (length) => {
  const options = [];
  for (let i = 0; i < length; i++) {
    options.push({ value: i + 1, label: i + 1 });
  }
  return options;
};

export const getAllowedTransferHubsIds = ({
  destinationHub,
  sourceHubId,
  allHubs
}) => {
  if (!destinationHub || !allHubs) return [];

  const {
    allowedTransfersHubIds: destinationAllowedHubIds = [],
    _id: destinationHubId
  } = destinationHub;

  const validHubIds = allHubs
    .filter(
      ({ _id, type }) =>
        ![SORTING_HUB, FIRST_MILE_HUB].includes(type) &&
        _id !== sourceHubId &&
        destinationAllowedHubIds.includes(_id)
    )
    .map(({ allowedTransfersHubIds }) => allowedTransfersHubIds);

  const combinedHubIds = [
    ...destinationAllowedHubIds,
    ...validHubIds.flat(),
    destinationHubId
  ];

  return [...new Set(combinedHubIds)];
};

export const formatPickupsData = (pickups = []) => {
  const formattedBusinessObject = {};
  let packageCount = 0;
  if (pickups.length || Object.keys(pickups).length) {
    pickups.forEach((pickup) => {
      if (pickup.type !== SOHO_PICKUP) {
        if (formattedBusinessObject[pickup?.business?.name]) {
          formattedBusinessObject[pickup?.business?.name] = {
            businessId: pickup?.business?._id,
            pickupRequestId: pickup?._id,
            deliveries: [
              ...formattedBusinessObject[pickup?.business?.name].deliveries,
              ...pickup.deliveries.filter(
                  (el) =>
                      !RECEIVED_AT_WAREHOUSE_CODE.includes(el.state.code) &&
                      pickup.type !== SOHO_PICKUP
              )
            ],
            deliveriesCount:
                pickup?.pickupsCounter - (pickup?.receivedOrdersCount ?? 0) || 0,
            originalDeliveriesCount: pickup?.pickupsCounter || 0
          };
        } else {
          formattedBusinessObject[pickup?.business?.name] = {
            businessId: pickup?.business?._id,
            pickupRequestId: pickup?._id,
            deliveries: pickup.deliveries.filter(
                (el) =>
                    !RECEIVED_AT_WAREHOUSE_CODE.includes(el.state.code) &&
                    pickup.type !== SOHO_PICKUP
            ),
            deliveriesCount:
                pickup?.pickupsCounter - (pickup?.receivedOrdersCount ?? 0) || 0,
            originalDeliveriesCount: pickup?.pickupsCounter || 0
          };
        }
      }
    });
    Object.values(formattedBusinessObject).forEach((business) => {
      packageCount += business.deliveries.length || business.deliveriesCount;
    });
  }

  return { formattedBusinessObject, packageCount };
};

export const validateScannedPackage = ({
                                         intl,
                                         item,
                                         isScannedFromSeal,
                                         selectedScanningType,
                                         scanType,
                                         packageWeight,
                                         packageNumber,
                                         packages,
                                         playBeep
                                       }) => {
  const itemIndexInPackages = packages.findIndex(
      (prevItem) => prevItem._id === item._id
  );

  if (
      itemIndexInPackages > -1 &&
      isInvalidMultiPackageScan({
        delivery: packages[itemIndexInPackages],
        packageNumber
      })
  ) {
    notify(
        `This ${
            isScannedFromSeal ? 'security seal numnber' : 'tracking number'
        }: ${item.trackingNumber} is already added!`,
        'error',
        true
    );
    return;
  }
  if (item.type === 'CASH_COLLECTION') {
    notify(
        `This package with tracking number ${item.trackingNumber} is cash collection.`,
        'error',
        true
    );
    return;
  }
  if (
      item.state.value === 'Delivered' ||
      item.state.value === 'Terminated' ||
      item.state.value.toLowerCase() === 'returned to business'
  ) {
    notify(
        `This package cannot be received because it is "${item.state.value}".`,
        'error',
        true
    );
    return;
  }
  if (item?.type === MAP_DELIVERY_TYPES.RTO && item?.routeId) {
    notify(
        intl.formatMessage(
            {
              id: 'hubs.receive_new_pickup.rto_assigned_to_route'
            },
            { routeId: item?.routeId }
        ),
        'error',
        true
    );
    return;
  }
  if (OFD_ORDERS_CODES.includes(item?.state?.code)) {
    notify(
        intl.formatMessage(
            {
              id: 'hubs.receive_new_pickup.order_is_ofd'
            },
            { routeId: item?.routeId }
        ),
        'error',
        true
    );
    return;
  }
  if (
      packageNumber > item.multiPackages ||
      (item.multiPackages && !packageNumber)
  ) {
    notify(
        intl.formatMessage({
          id: 'hubs.receive_new_pickup.invalid_multipackage'
        }),
        'error',
        true
    );
    return;
  }

  if (INITIAL_STATES.includes(item.state.code) && item.restoredOrder) {
    showNotification(RESTORED_DELIVERY, item);
  }

  const pendingActionType = getDeliveryPendingActions(item);
  let actionType;

  if (
      item?.state?.exception &&
      RETURN_REJECTED_EXCEPTION_CODES.includes(item.state.exception[0]?.code)
  ) {
    openModal(BRScanningPopup, {
      actionTitle: intl.formatMessage({
        id: 'hubs.receive_from_hub.rejected_return_error'
      }),
      bgClassName: 'bg-red',
      closeAutomatically: true
    });
    actionType = REJECTED_RETURN;
  } else if (pendingActionType) {
    showNotification(pendingActionType, item);
    actionType = pendingActionType;
  }

  playBeep?.();

  const selectedPackageSize = PACKAGE_SIZES.find(
      (packageSize) => packageSize.value === selectedScanningType
  );
  const updatedItem = {
    ...item,
    pricingPackageSize: selectedPackageSize?.value || 'Normal',
    packageSize: selectedPackageSize?.oldPricing,
    scanType,
    actionType,
    packageWeight: Math.ceil(item?.specs?.weight) || packageWeight,
    ...(packageNumber && { scannedPackages: [packageNumber] })
  };

  if (packageNumber && itemIndexInPackages > -1) {
    packages[itemIndexInPackages].scannedPackages.push(packageNumber);
    return;
  }

  return updatedItem;
};

export const formatPackages = (packages) => {
  let formattedObject = {};
  packages.forEach((item) => {
    if (formattedObject[item?.sender?.name]) {
      formattedObject[item?.sender?.name].deliveries.push(item);
    } else {
      formattedObject = {
        ...formattedObject,
        [item?.sender?.name]: {
          deliveries: [item]
        }
      };
    }
  });
  return formattedObject;
};

export const formatReceivedOrders = (packages) => {
  const formattedPayload = {
    pickups: []
  };
  Object.values(formatPackages(packages)).forEach((business) => {
    if (business.deliveries?.length) {
      formattedPayload.pickups.push({
        pickupRequestId: business.deliveries[0].pickupRequestId,
        receivedOrdersCount: business.deliveries?.length
      });
    }
  });
  return formattedPayload;
};

export const addWrongScanLog = async (ids) => {
  const payload = {
    searchType: DELIVERIES_SCAN_TYPES.PICKUPS_RECEIVE_ORDER_SCAN,
    trackingNumbers: ids
  };
  try {
    await removeScannedDeliveries(payload);
  } catch (error) {
    notify(error.message);
  }
};