import { useIntl } from 'react-intl';

import './DeliveryImages.less';

const DeliveryImages = ({ consigneeImages, starImages }) => {
  const intl = useIntl();
  return (
    <div className="br-delivery-images__container">
      {!!consigneeImages?.length && (
        <div className="br-delivery-images__section-wrapper">
          <span className="br-delivery-images__section-header">
            {intl.formatMessage({
              id: 'deliveries.delivery_details.delivery_images.consignee_images_header'
            })}
          </span>
          <div className="br-delivery-images__images-wrapper">
            {consigneeImages.map((img) => (
              <img
                className="br-delivery-images__single-image"
                src={img?.imageUrl}
              />
            ))}
          </div>
        </div>
      )}
      {!!starImages?.length && (
        <>
          <span className="br-delivery-images__section-header">
            {intl.formatMessage({
              id: 'deliveries.delivery_details.delivery_images.star_images_header'
            })}
          </span>
          <div className="br-delivery-images__images-wrapper">
            {starImages.map((img) => (
              <img className="br-delivery-images__single-image" src={img} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
export default DeliveryImages;
