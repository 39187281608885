import { Modal } from 'antd';
import classNames from 'classnames';
import { CloseOutlined } from '@ant-design/icons';

import ImagesCarousel from 'components/ImagesCarousel/ImagesCarousel';

import { ReactComponent as BusinessesIcon } from 'assets/bosta-icons/Shop.svg';
import { ReactComponent as Truck } from 'assets/bosta-icons/truck.svg';

import './ViewImagesModal.less';

const ViewImagesModal = ({ close, messages, item, intl, record, ...props }) => {
  const productImages = item?.starProofOfReturnedPackages?.map((img) => {
    return img;
  });
  const businessImages = item?.businessProofOfReturnedPackages?.map((img) => {
    return img?.imageUrl;
  });

  const isSingleImageTypeIncluded = () => {
    return !productImages?.length || !businessImages?.length;
  };
  return (
    <Modal
      {...props}
      closeIcon={<CloseOutlined />}
      closable={true}
      footer={null}
      title={intl.formatMessage({ id: 'hubs.tabs.images' })}
      onCancel={() => close()}
      className={classNames('br-modal-container', {
        'br-single-image-container': isSingleImageTypeIncluded()
      })}
    >
      <div className="br-view-images-modal__wrapper">
        {!!businessImages?.length && (
          <div className="images-section">
            <div className="images-section__header">
              <BusinessesIcon />
              <span className="display-xs">
                {intl.formatMessage({ id: 'hubs.tabs.by_bussines' })}
              </span>
            </div>

            <div className="br-print-awb-modal__images-slider">
              <ImagesCarousel imagesArray={businessImages} intl={intl} />
            </div>
          </div>
        )}
        {!!productImages?.length && (
          <div className="images-section">
            <div className="images-section__header">
              <Truck />
              <span className="display-xs">
                {intl.formatMessage({ id: 'hubs.tabs.by_star' })}
              </span>
            </div>

            <div className="br-print-awb-modal__images-slider">
              <ImagesCarousel imagesArray={productImages} intl={intl} />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ViewImagesModal;
