import React from 'react';
import { Row, Col, Button } from 'antd';
import classnames from 'classnames';

import { openModal } from 'utils/modal';
import {
  DELIVERY_DETAILS_SECTIONS,
  ORDER_TYPES_VALUE
} from 'constants/shipment-details';
import { formatSignAndReturnType } from 'utils/deliveries';
import { COMPLETED_ORDERS_REACHES_FINAL_STATES_CODES } from 'constants/hubs';
import {
  isUserAuthorized,
  isDefaultBostaCourier,
  isDSPVendor
} from 'utils/helpers';
import aclMatrix, { VENDOR_MANAGER } from 'common/aclMatrix';
import { DELIVERY_RATING_ITEMS } from 'constants/Deliveries';

import Notes from 'components/Shipments/components/NewDeliveryDetails/components/Notes/Notes';
import DeliveryImages from 'components/Shipments/components/NewDeliveryDetails/components/DeliveryImages/DeliveryImages';
import DeliveryHighlights from 'components/Shipments/components/NewDeliveryDetails/components/DeliveryHighlights/DeliveryHighlights';
import EditDeliveryDetailsModal from 'components/Shipments/components/NewDeliveryDetails/components/EditDeliveryDetailsModal/EditDeliveryDetailsModal';
import EditReturnDetailsModal from 'components/Shipments/components/NewDeliveryDetails/components/EditReturnDetailsModal/EditReturnDetailsModal';
import EditCustomerDetailsModal from 'components/Shipments/components/NewDeliveryDetails/components/EditCustomerDetailsModal/EditCustomerDetailsModal';
import { notify } from 'components/Notify/Notify';
import StarNotes from 'components/Shipments/components/NewDeliveryDetails/components/StarNotes/StarNotes';
import ServiceRating from 'components/ServiceRating/ServiceRating';
import UpdateItemValueModal from 'components/Shipments/CreateEditOrder/OrderDetails/ItemValue/UpdateItemValueModal';

import { ReactComponent as OperationDetailsSectionIcon } from 'assets/imgRevamp/NewOperationDetailsSectionIcon.svg';
import { ReactComponent as DeliveryDetailsSectionIcon } from 'assets/imgRevamp/NewDeliveryDetailsSectionIcon.svg';
import { ReactComponent as PricingSectionIcon } from 'assets/imgRevamp/NewPricingDetailsSectionIcon.svg';
import { ReactComponent as PickupDetailsSectionIcon } from 'assets/imgRevamp/NewPickupDetailsSectionIcon.svg';
import { ReactComponent as CustomerDetailsSectionIcon } from 'assets/imgRevamp/NewCustomerDetailsSectionIcon.svg';
import { ReactComponent as DeliveryHighlightsSectionIcon } from 'assets/imgRevamp/NewDeliveryHighlightsSectionIcon.svg';
import { ReactComponent as NotesSectionIcon } from 'assets/imgRevamp/NewNotesSectionIcon.svg';
import { ReactComponent as ReturnSectionIcon } from 'assets/imgRevamp/NewReturnInfoSectionIcon.svg';
import { ReactComponent as ConsigneeRateIcon } from 'assets/imgRevamp/message.svg';
import { ReactComponent as ImageSection } from 'assets/bosta-icons/image-section.svg';
import { ReactComponent as CoinsStackedIcon } from 'assets/bosta-icons/coins-stacked.svg';

import './OrderInfoSection.less';

const OrderInfoSection = ({
  title,
  logoIndex,
  isEditable,
  rowColumnMapping = [],
  secondHeader,
  secondRowColumnMapping,
  isSmallCol = false,
  notes = [],
  fixedHeight = false,
  content = [],
  secondSectionContent = [],
  delivery,
  setIsLoading,
  onSuccess,
  allowedToRefund,
  deliveryImage,
  starNotes = [],
  businessDetails,
  className
}) => {
  const LOGOS_MAPPING = {
    delivery_details: <DeliveryDetailsSectionIcon />,
    operation_details: <OperationDetailsSectionIcon />,
    pricing_details: <PricingSectionIcon />,
    promotion_details: <PricingSectionIcon />,
    pickup_details: <PickupDetailsSectionIcon />,
    customer_details: <CustomerDetailsSectionIcon />,
    delivery_highlights: <DeliveryHighlightsSectionIcon />,
    notes: <NotesSectionIcon />,
    return_details: <ReturnSectionIcon />,
    star_notes: <NotesSectionIcon />,
    delivery_images: <ImageSection />,
    [DELIVERY_DETAILS_SECTIONS.CONSIGNEE_RATE]: <ConsigneeRateIcon />,
    item_value: <CoinsStackedIcon />
  };

  let cloneArr = rowColumnMapping.slice(0);

  const firstPartOfRowColumnMapping = cloneArr.splice(0, 4);
  const secondPartOfRowColumnMapping = cloneArr.splice(-4);

  const handleRenderingInfoSectionContent = () => {
    if (logoIndex === 'notes') {
      return (
        <Notes notes={notes} delivery={delivery} setIsLoading={setIsLoading} />
      );
    } else if (logoIndex === 'delivery_highlights') {
      return <DeliveryHighlights delivery={delivery} />;
    } else if (logoIndex === 'star_notes') {
      return (
        <StarNotes
          starNotes={starNotes}
          delivery={delivery}
          setIsLoading={setIsLoading}
        />
      );
    } else if (logoIndex === DELIVERY_DETAILS_SECTIONS.CONSIGNEE_RATE) {
      return (
        <ServiceRating
          rating={delivery?.rating}
          ratingsList={DELIVERY_RATING_ITEMS}
        />
      );
    } else if (logoIndex === 'delivery_images') {
      return (
        <DeliveryImages
          consigneeImages={delivery?.extraData?.businessProofOfReturnedPackages}
          starImages={delivery?.extraData?.starProofOfReturnedPackages}
        />
      );
    }
  };

  const handleEditButtonClick = () => {
    const deliveryItem =
      delivery?.type === ORDER_TYPES_VALUE.CRP
        ? delivery?.returnSpecs
        : delivery?.specs;

    const customerAddress = [
      ORDER_TYPES_VALUE.CRP,
      ORDER_TYPES_VALUE.CASH_COLLECTION
    ].includes(delivery?.type)
      ? delivery?.pickupAddress
      : delivery?.dropOffAddress;

    const customerAddressName = [
      ORDER_TYPES_VALUE.CRP,
      ORDER_TYPES_VALUE.CASH_COLLECTION
    ].includes(delivery?.type)
      ? 'pickupAddress'
      : 'dropOffAddress';

    const customerReturnAddress =
      delivery?.type === ORDER_TYPES_VALUE.CRP
        ? delivery?.dropOffAddress
        : delivery?.returnAddress;
    if (
      COMPLETED_ORDERS_REACHES_FINAL_STATES_CODES.includes(
        delivery?.state?.code
      )
    ) {
      notify(
        'This order is in a final state. You can’t edit any of its details.'
      );
    } else if (logoIndex === 'delivery_details') {
      openModal(EditDeliveryDetailsModal, {
        delivery: delivery,
        setIsLoading: setIsLoading,
        onSuccess: onSuccess,
        initialValues: {
          itemsCount: deliveryItem?.packageDetails?.itemsCount || '1',
          cod: delivery?.originalCod || '',
          businessReference: delivery?.businessReference || '',
          packageType: deliveryItem?.packageType || '',
          pricingPackageSize: delivery?.pricingPackageSize?.name || '',
          allowToOpenPackage: delivery?.allowToOpenPackage || false,
          description: deliveryItem?.packageDetails?.description || '',
          notes: delivery?.notes || '',
          weight: delivery?.specs?.weight,
          payWithBostaCredits: delivery?.payWithBostaCredits
        },
        allowedToRefund: allowedToRefund,
        businessDetails
      });
    } else if (logoIndex === 'customer_details') {
      openModal(EditCustomerDetailsModal, {
        delivery: delivery,
        setIsLoading: setIsLoading,
        onSuccess: onSuccess,
        customerAddressName: customerAddressName,
        initialValues: {
          receiver: {
            fullName: delivery?.receiver?.fullName || '',
            phone: delivery?.receiver?.phone || '',
            secondPhone: delivery?.receiver?.secondPhone || ''
          },
          [customerAddressName]: {
            cityCode: customerAddress?.city?._id || '',
            districtId: customerAddress?.district?._id || '',
            firstLine: customerAddress?.firstLine || '',
            apartment: customerAddress?.apartment || '',
            floor: customerAddress?.floor || '',
            buildingNumber: customerAddress?.buildingNumber || '',
            isWorkAddress: customerAddress?.isWorkAddress || false,
            secondLine: customerAddress?.secondLine || ''
          }
        }
      });
    } else if (logoIndex === 'return_details') {
      openModal(EditReturnDetailsModal, {
        delivery: delivery,
        setIsLoading: setIsLoading,
        onSuccess: onSuccess,
        initialValues: {
          itemsCount: delivery?.returnSpecs?.packageDetails?.itemsCount || '1',
          description: delivery?.returnSpecs?.packageDetails?.description || '',
          locationId: customerReturnAddress?.businessLocationId || ''
        }
      });
    } else if (logoIndex === 'item_value') {
      openModal(UpdateItemValueModal, {
        delivery: delivery,
        setIsLoading: setIsLoading,
        onSuccess: onSuccess,
        state: delivery?.state?.code,
        initialValues: {
          id: delivery?._id,
          businessId: delivery?.holder?._id,
          amount: delivery?.goodsInfo?.amount,
          proofOfCostAttachmentType:
            delivery?.goodsInfo?.proofOfCostAttachmentType,
          proofOfCostAttachmentUrl:
            delivery?.goodsInfo?.proofOfCostAttachmentUrl
        }
      });
    }
  };

  return (
    <div
      className={classnames(
        'br-order-info-container',
        {
          'br-order-info__col-8': isSmallCol,
          [logoIndex]: fixedHeight
        },
        className
      )}
    >
      <div className="br-order-info-section__header">
        <div className="br-order-info-section__title">
          {LOGOS_MAPPING[logoIndex]}
          <p> {title}</p>
        </div>

        {isEditable &&
        (isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) ||
          isDSPVendor()) ? (
          <Button
            type="secondary"
            onClick={handleEditButtonClick}
            disabled={
              delivery?.type === ORDER_TYPES_VALUE.SIGN_AND_RETURN &&
              formatSignAndReturnType(delivery) === 'Sign & Return - Pickup'
            }
          >
            Edit
          </Button>
        ) : null}
      </div>
      {rowColumnMapping.length ? (
        <div className="br-order-info-section__content">
          <div className="display-flex">
            {deliveryImage && (
              <span className="br-order-info-section__content__image">
                <img src={deliveryImage} alt="Delivery image" />
              </span>
            )}
            <div>
              {!!firstPartOfRowColumnMapping.length &&
                firstPartOfRowColumnMapping.map((numberOfColumns, indexR) => {
                  return (
                    <Row className="br-order-info__row-content">
                      {numberOfColumns.map((colSpan, indexC) => (
                        <Col span={colSpan}>
                          <div className="br-order-info__col-data">
                            <span>
                              {content && content.length
                                ? content[indexR][indexC]?.name
                                : 'name'}
                            </span>
                            <span>
                              {content && content.length
                                ? content[indexR][indexC]?.value
                                : 'name'}
                            </span>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  );
                })}
            </div>
          </div>
          {!!secondPartOfRowColumnMapping?.length &&
            secondPartOfRowColumnMapping.map((numberOfColumns, indexR) => {
              return (
                <Row className="br-order-info__row-content">
                  {numberOfColumns.map((colSpan, indexC) => (
                    <Col span={colSpan}>
                      <div className="br-order-info__col-data">
                        <span>
                          {content && content.length
                            ? content[indexR + 4][indexC]?.name
                            : 'name'}
                        </span>
                        <span>
                          {content && content.length
                            ? content[indexR + 4][indexC]?.value
                            : 'name'}
                        </span>
                      </div>
                    </Col>
                  ))}
                </Row>
              );
            })}
          {secondHeader && (
            <p className="br-order-info-section__second-header">
              {secondHeader}
            </p>
          )}
          {secondRowColumnMapping &&
            secondRowColumnMapping.map((numberOfColumns, indexR) => {
              return (
                <Row className="br-order-info__row-content">
                  {numberOfColumns.map((colSpan, indexC) => (
                    <Col span={colSpan}>
                      <div className="br-order-info__col-data">
                        <span>
                          {secondSectionContent && secondSectionContent.length
                            ? secondSectionContent[indexR][indexC].name
                            : 'name'}
                        </span>
                        <span>
                          {secondSectionContent && secondSectionContent.length
                            ? secondSectionContent[indexR][indexC].value
                            : 'name'}
                        </span>
                      </div>
                    </Col>
                  ))}
                </Row>
              );
            })}
        </div>
      ) : (
        <div className="br-order-info-section__content br-order-info-section__center-content">
          {handleRenderingInfoSectionContent()}
        </div>
      )}
    </div>
  );
};

export default OrderInfoSection;
