import React, { useCallback, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import xlsx from 'xlsx';
import { Button, FormGroup } from 'react-bootstrap';
import { Modal, InputNumber, Form, Select, Checkbox } from 'antd';
import dayjs from 'dayjs';
import { injectIntl } from 'react-intl';
import isNil from 'lodash/isNil';

import { NORMAL_PACKAGE_TYPE } from 'constants/pickups';
import { RETURN_REJECTED_EXCEPTION_CODES } from 'constants/shipments';
import {
  checkOrderExistance,
  deliveriesDebrief,
  setConfirmedPickupsDeliveries,
  getPickupStars,
  removeScannedDeliveries
} from 'services/hubs';

import {
  MAP_DELIVERY_TYPES,
  OFD_ORDERS_CODES,
  PACKAGE_SIZES,
  SOHO_PICKUP,
  RECEIVED_AT_WAREHOUSE_CODE,
  DELIVERIES_SCAN_TYPES,
  REJECTED_RETURN
} from 'constants/hubs';
import { beep, warning } from 'actions';
import { receivePackagesAtHub } from 'actions/DeliveriesActions';
import { getAllPriceSize } from 'services/priceTiers';
import { scanDeliveries, setMissedPickupsDeliveries } from 'services/hubs';
import {
  getDeliveryPendingActions,
  isInvalidMultiPackageScan,
  showNotification
} from 'utils/hubs';
import { IS_SAUDI } from 'constants/helper';
import { openModal } from 'utils/modal';
import { INITIAL_STATES } from 'constants/shipments';
import { RESTORED_DELIVERY } from 'constants/action-center';
import { DELIVERY_UPDATE_TYPE } from 'constants/Deliveries';

import NewSwappingContainer from 'components/Hub/PackagesDebrief/PackagesDebriefTabs/NewSwappingContainer/NewSwappingContainer';
import PickupsTabActions from 'components/Hub/PackagesDebrief/PackagesDebriefTabs/PickupsTabActions/PickupsTabActions';
import MissingMultiPackagesModal from 'components/MultiPackages/components/MissingMultiPackagesModal/MissingMultiPackagesModal';
import { notify } from 'components/Notify/Notify';
import { fmt } from 'components/IntlWrapper/IntlWrapper';
import BRScanningPopup from 'components/BRScanningPopup/BRScanningPopup';

import styles from '../styles.module.scss';
import './ReceiveNewPickups.less';

const useWeights = IS_SAUDI;

const packagesColumns = [
  { dataIndex: 'trackingNumber', title: 'Tracking Number' },
  { dataIndex: 'business', title: 'Business' },
  { dataIndex: 'state', title: 'State' },
  { dataIndex: 'numberOfAttempts', title: 'Number Of Attempts' },
  // { dataIndex: 'packageSize', title: 'package size' },
  {
    dataIndex: `${useWeights ? 'packageWeight' : 'pricingPackageSize'}`,
    title: `${useWeights ? 'Package Weight' : 'Pricing Package Size'}`,
    render: (value) => (
      <span>
        {useWeights
          ? fmt(
            { id: 'common.weight_kg' },
            {
              weight: value
            }
          )
          : value}
      </span>
    ),
    editable: useWeights,
    width: 250
  },
  { dataIndex: 'actionType', title: 'Type' },
  { dataIndex: 'actions', title: 'Actions', align: 'center' }
];

/**
 * create xlsx file to
 * @param deliveries
 */
const createXLSX = (deliveries = [], packageSize) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  if (deliveries.length === 0) return;
  const mappedDeliveries = deliveries.map((el) => ({
    TN: el.trackingNumber,
    Business: el.sender.name,
    'Received by': `${userInfo?.profile?.firstName} ${
      userInfo?.profile?.lastName || ''
    }`,
    Mode: el.scanType,
    'Size Value': el?.pricingPackageSize || NORMAL_PACKAGE_TYPE,
    'Weight Value': el?.packageWeight
  }));

  const ws = xlsx.utils.json_to_sheet(mappedDeliveries);
  const wb = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, ws, 'Deliveries');
  xlsx.writeFile(wb, `Deliveries-${dayjs(new Date()).tz().format('LL')}.xlsx`);
};

const ReceiveNewPickups = ({ playBeep, intl }) => {
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const [packageSize, setPackageSize] = useState(0);
  const [pricingPackageSize, setPricingPackageSize] = useState({});
  const [allPricingPackageSize, setAllPricingPackageSize] = useState([]);
  const [stars, setStars] = useState([]);
  const [selectedStar, setSelectedStar] = useState(false);
  const [pickups, setPickups] = useState({});
  const [firstMilePickupsCount, setFirstMilePickupCount] = useState(0);
  const [isMissedFirstMilePickups, setIsMissedFirstMilePickups] =
    useState(false);
  const [isGroupByBusiness, setIsGroupByBusiness] = useState(true);

  const [form] = Form.useForm();

  const EditableCell = ({
                          title,
                          editable,
                          children,
                          dataIndex,
                          record,
                          handleWeightChange,
                          ...restProps
                        }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex]
      });
    };

    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleWeightChange({ ...record, ...values });
      } catch {
        toggleEdit();
      }
    };

    let childNode = children;

    if (editable) {
      childNode = editing ? (
        <Form.Item
          name={dataIndex}
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: 'hubs.receive_new_pickup.weight_required'
              })
            }
          ]}
        >
          <InputNumber
            className="br-receive-new-pickups__table-input"
            placeholder={intl.formatMessage({
              id: 'hubs.receive_new_pickup.pickups_tab_actions.weight_placeholder'
            })}
            ref={inputRef}
            min={1}
            step={1}
            precision={0}
            onPressEnter={save}
            onBlur={save}
          />
        </Form.Item>
      ) : (
        <div className="editable-cell-value-wrap" onClick={toggleEdit}>
          {children}
        </div>
      );
    }

    return <td {...restProps}>{childNode}</td>;
  };

  const rowSelection = {
    /**
     * on checkbox state change for each row
     */
    onChange: (selectedRowKeys) => {
      setSelectedRowsIds([...selectedRowKeys]);
    }
  };
  const getAllPriceSizes = async () => {
    const priceSize = await getAllPriceSize();
    setAllPricingPackageSize(
      priceSize?.data?.sizes.map((item) => {
        const oldPricing = ['Normal', 'White Bag'].indexOf(item.name);
        return {
          ...item,
          name:
            item.alias && item.alias !== item.name
              ? `${item.alias} (${item.name})`
              : item.name,
          value: item.name,
          oldPricing: oldPricing === -1 ? 0 : oldPricing,
          dimmed: ['Large', 'X-Large'].includes(item.name)
        };
      })
    );
    setPackageSize(0);
    setPricingPackageSize(
      priceSize?.data?.sizes.find((item) => item.name === 'Normal')
    );
  };

  useEffect(() => {
    !useWeights && getAllPriceSizes();
    getAllHubStars();
  }, []);

  useEffect(() => {
    if (selectedStar) {
      getStarDebreifPickups();
    }
  }, [selectedStar]);

  const getAllHubStars = async () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    try {
      const { message } = await getPickupStars();
      message &&
      setStars(message.filter((el) => el.emails?.[0]?.verified) || []);
    } catch (error) {
      notify(error.message);
    }
  };

  const getStarDebreifPickups = async () => {
    setLoading(true);
    try {
      const { pickups } = await deliveriesDebrief(selectedStar);
      const { formattedBusinessObject, packageCount } =
        formatPickupsData(pickups);
      setPickups(formattedBusinessObject);
      setFirstMilePickupCount(packageCount);
    } catch (error) {
      notify(error.message);
    }
    setLoading(false);
  };

  const formatPickupsData = (pickups = []) => {
    let formattedBusinessObject = {};
    let packageCount = 0;
    if (pickups.length || Object.keys(pickups).length) {
      pickups.forEach((pickup) => {
        if (pickup.type !== SOHO_PICKUP) {
          if (formattedBusinessObject[pickup?.business?.name]) {
            formattedBusinessObject[pickup?.business?.name] = {
              businessId: pickup?.business?._id,
              pickupRequestId: pickup?._id,
              deliveries: [
                ...formattedBusinessObject[pickup?.business?.name].deliveries,
                ...pickup.deliveries.filter(
                  (el) =>
                    !RECEIVED_AT_WAREHOUSE_CODE.includes(el.state.code) &&
                    pickup.type !== SOHO_PICKUP
                )
              ],
              deliveriesCount:
                pickup?.pickupsCounter - (pickup?.receivedOrdersCount ?? 0) ||
                0,
              originalDeliveriesCount: pickup?.pickupsCounter || 0
            };
          } else {
            formattedBusinessObject[pickup?.business?.name] = {
              businessId: pickup?.business?._id,
              pickupRequestId: pickup?._id,
              deliveries: pickup.deliveries.filter(
                (el) =>
                  !RECEIVED_AT_WAREHOUSE_CODE.includes(el.state.code) &&
                  pickup.type !== SOHO_PICKUP
              ),
              deliveriesCount:
                pickup?.pickupsCounter - (pickup?.receivedOrdersCount ?? 0) ||
                0,
              originalDeliveriesCount: pickup?.pickupsCounter || 0
            };
          }
        }
      });
      Object.values(formattedBusinessObject).forEach((business) => {
        packageCount += business.deliveries.length || business.deliveriesCount;
      });
    }

    return { formattedBusinessObject, packageCount };
  };

  const handleChangeOfPackages = ({
                                    item,
                                    isScannedFromSeal,
                                    selectedScanningType,
                                    scanType,
                                    packageWeight,
                                    packageNumber
                                  }) => {
    setPackages((prev) => {
      const foundIndex = prev.findIndex(
        (prevItem) => prevItem._id === item._id
      );

      if (
        foundIndex > -1 &&
        isInvalidMultiPackageScan({ delivery: prev[foundIndex], packageNumber })
      ) {
        notify(
          `This ${
            isScannedFromSeal ? 'security seal numnber' : 'tracking number'
          }: ${item.trackingNumber} is already added!`,
          'error',
          true
        );
        return [...prev];
      }
      if (item.type === 'CASH_COLLECTION') {
        notify(
          `This package with tracking number ${item.trackingNumber} is cash collection.`,
          'error',
          true
        );
        return [...prev];
      }
      if (
        item.state.value === 'Delivered' ||
        item.state.value === 'Terminated' ||
        item.state.value.toLowerCase() === 'returned to business'
      ) {
        notify(
          `This package cannot be received because it is "${item.state.value}".`,
          'error',
          true
        );
        return [...prev];
      }
      if (item?.type === MAP_DELIVERY_TYPES.RTO && item?.routeId) {
        notify(
          intl.formatMessage(
            {
              id: 'hubs.receive_new_pickup.rto_assigned_to_route'
            },
            { routeId: item?.routeId }
          ),
          'error',
          true
        );
        return [...prev];
      }
      if (OFD_ORDERS_CODES.includes(item?.state?.code)) {
        notify(
          intl.formatMessage(
            {
              id: 'hubs.receive_new_pickup.order_is_ofd'
            },
            { routeId: item?.routeId }
          ),
          'error',
          true
        );
        return [...prev];
      }
      if (
        packageNumber > item.multiPackages ||
        (item.multiPackages && !packageNumber)
      ) {
        notify(
          intl.formatMessage({
            id: 'hubs.receive_new_pickup.invalid_multipackage'
          }),
          'error',
          true
        );
        return [...prev];
      }

      if (INITIAL_STATES.includes(item.state.code) && item.restoredOrder) {
        showNotification(RESTORED_DELIVERY, item);
      }

      const pendingActionType = getDeliveryPendingActions(item);
      let actionType;

      if (
        item?.state?.exception &&
        RETURN_REJECTED_EXCEPTION_CODES.includes(item.state.exception[0]?.code)
      ) {
        openModal(BRScanningPopup, {
          actionTitle: intl.formatMessage({
            id: 'hubs.receive_from_hub.rejected_return_error'
          }),
          bgClassName: 'bg-red',
          closeAutomatically: true
        });
        actionType = REJECTED_RETURN;
      } else if (pendingActionType) {
        showNotification(pendingActionType, item);
        actionType = pendingActionType;
      }

      playBeep();

      const selectedPackageSize = PACKAGE_SIZES.find(
        (item) => item.value === selectedScanningType
      );
      item = {
        ...item,
        pricingPackageSize: selectedPackageSize?.value || 'Normal',
        packageSize: selectedPackageSize?.oldPricing,
        scanType,
        actionType,
        packageWeight: Math.ceil(item?.specs?.weight) || packageWeight,
        ...(packageNumber && { scannedPackages: [packageNumber] })
      };

      if (packageNumber && foundIndex > -1) {
        prev[foundIndex].scannedPackages.push(packageNumber);

        return prev;
      }

      return [...prev, item];
    });
  };

  const changeSearchFor = debounce(
    async (
      value,
      selectedScanningType,
      resetInputs,
      scanType,
      packageWeight,
      packageSizeRef,
      selectedStar,
      firstMilePickupsCount,
      pickups
    ) => {
      setLoading(true);
      const re = /^[0-9\b]+$/;
      const [trackingNumber, packageNumber] = value.split('/');
      const parsedPackageNumber = parseInt(packageNumber);

      if (trackingNumber.length > 13) {
        notify(
          'the tracking number should be 7 numbers or 6 numbers or 10 numbers '
        );
        setLoading(false);
      } else if (
        !isNil(packageNumber) &&
        (!re.test(parsedPackageNumber) || parsedPackageNumber === 0)
      ) {
        notify(
          intl.formatMessage({
            id: 'hubs.receive_new_pickup.invalid_multipackage'
          })
        );
        setLoading(false);
      } else if (!re.test(trackingNumber)) {
        notify('The tracking number you entered should be number only!');
        setLoading(false);
      } else {
        try {
          let item;
          const payload = {
            trackingNumbers: [trackingNumber],
            searchType: DELIVERIES_SCAN_TYPES.PICKUPS_RECEIVE_ORDER_SCAN
          };

          const { data } = await scanDeliveries(payload);
          item = data[0];

          if (item) {
            setPricingPackageSize(item?.pricingPackageSize);
            handleChangeOfPackages({
              item,
              isScannedFromSeal: false,
              selectedScanningType,
              scanType,
              packageWeight,
              packageNumber: parsedPackageNumber
            });
            handleChangeOfPickups({
              item
            });
            if (packageSizeRef?.current) {
              packageSizeRef.current.focus({
                cursor: 'start'
              });
            }
          } else {
            notify(
              'The tracking number you entered is not valid!',
              'error',
              true
            );
          }
        } catch (error) {
          notify(error.message);
        }
        setLoading(false);
        resetInputs(scanType === 'Selected');
      }
    },
    500
  );

  const handleChangeOfPickups = ({ item }) => {
    const businessName = item.sender?.name;
    if (pickups[businessName]) {
      if (pickups[businessName].originalDeliveriesCount) {
        pickups[businessName] = {
          ...pickups[businessName],
          deliveriesCount: pickups[businessName].deliveriesCount - 1
        };
      } else {
        pickups[businessName] = {
          ...pickups[businessName],
          deliveries: pickups[businessName].deliveries.filter(
            (delivery) => delivery.trackingNumber !== item.trackingNumber
          )
        };
      }

      setFirstMilePickupCount(firstMilePickupsCount - 1);
    }
  };

  const changeSearchForSealNumber = debounce(
    async (value, selectedScanningType, resetInputs, scanType) => {
      setLoading(true);
      const re = /^[0-9\b]+$/;
      if (!re.test(value)) {
        notify('The Seal number you entered should be number only!');
      } else {
        try {
          let item = await checkOrderExistance({ sealCode: value });
          if (item) {
            setPricingPackageSize(item?.pricingPackageSize);
            handleChangeOfPackages({
              item,
              selectedScanningType,
              isScannedFromSeal: true,
              scanType
            });
          } else {
            notify('The seal number you entered is not valid!', 'error', true);
          }
          resetInputs(scanType === 'Selected');
        } catch (error) {
          notify('Security seal number doesn’t exist', 'error', true);
        }
      }
      setLoading(false);
    },
    500
  );

  const handleChange = useCallback(changeSearchFor, [
    allPricingPackageSize,
    selectedStar,
    firstMilePickupsCount,
    pickups
  ]);
  const handleChangeSealNumber = useCallback(changeSearchForSealNumber, [
    allPricingPackageSize
  ]);

  const handleTrackingChange = useCallback(
    (
      value,
      selectedScanningType,
      resetInputs,
      scanType,
      packageWeight,
      packageSizeRef
    ) => {
      if (packages.length === 300) {
        notify(
          `You have reached the maximum number of receiving package per time.`
        );
        return;
      }
      handleChange(
        value.trim(),
        selectedScanningType,
        resetInputs,
        scanType,
        packageWeight,
        packageSizeRef,
        selectedStar,
        firstMilePickupsCount,
        pickups
      );
    },
    [allPricingPackageSize, selectedStar, firstMilePickupsCount, pickups]
  );

  const handleSealNumbersChange = useCallback(
    (value, selectedScanningType, resetInputs, scanType) => {
      if (packages.length === 300) {
        notify(
          `You have reached the maximum number of receiving package per time.`
        );
      }
      handleChangeSealNumber(
        value.trim(),
        selectedScanningType,
        resetInputs,
        scanType,
        { isSealNumber: true }
      );
    },
    [allPricingPackageSize]
  );

  const formatReceivedOrders = () => {
    let formattedPayload = {
      pickups: []
    };
    Object.values(formatPackages()).map((business) => {
      if (business.deliveries?.length) {
        formattedPayload.pickups.push({
          pickupRequestId: business.deliveries[0].pickupRequestId,
          receivedOrdersCount: business.deliveries?.length
        });
      }
    });
    return formattedPayload;
  };

  const handleSaveCurrentPackages = async () => {
    setLoading(true);

    const res = await receivePackagesAtHub({
      updateType: DELIVERY_UPDATE_TYPE.RECEIVE_MANY_NEW_PICKUPS,
      deliveries: packages.map(
        ({ _id, packageSize, pricingPackageSize, scanType, packageWeight }) => {
          return {
            _id,
            packageSize,
            pricingPackageSize,
            scanType,
            packageWeight
          };
        }
      )
    });
    if (res) {
      if (res.failedDeliveries?.length > 0) {
        notify(
          `Although the success of receiving the packages there are some package failed to be received on the system, please review the following packages status: ${res.failedDeliveries.join(
            ', '
          )} `,
          'error'
        );
      } else {
        notify('Deliveries received successfully!', 'success');
      }
      if (firstMilePickupsCount > 0) {
        setIsMissedFirstMilePickups(true);
        Modal.confirm({
          content: (
            <div>
              Some pickups are missing, are you sure you want to confirm
              receiving without scanning them?
            </div>
          ),
          okButtonProps: { className: 'primaryButton' },
          cancelButtonProps: { className: 'secondaryButton' },
          onOk: async () => {
            handleSendingMissedPickups();
          }
        });
      }
      const filteredPackages = res.failedDeliveries
        ? packages.filter(
          (el) =>
            !res.failedDeliveries.some(
              (trackingNumber) => el.trackingNumber === trackingNumber
            )
        )
        : [...packages];
      const errorPackages = res.failedDeliveries
        ? packages.filter((el) =>
          res.failedDeliveries.some(
            (trackingNumber) => el.trackingNumber === trackingNumber
          )
        )
        : [];
      createXLSX(filteredPackages, pricingPackageSize.name);
      setPackages(errorPackages);
      setPackageSize(null);
      const payload = formatReceivedOrders();
      if (payload?.pickups?.length) {
        await setConfirmedPickupsDeliveries(formatReceivedOrders());
      }
    }
    setLoading(false);
  };

  const handleClearAll = () => {
    const trackingNumbers = packages.map(
      ({ trackingNumber }) => trackingNumber
    );
    addWrongScanLog(trackingNumbers);
    setPackages([]);
    setPackageSize(null);
  };

  const removeFromScannedItem = (id) => {
    const item = packages.find((el) => el._id === id);
    if (item) {
      setPackages((prevState) => {
        const newState = prevState.filter((el) => el._id !== id);
        return [...newState];
      });
    }
  };

  const removeSelected = () => {
    setPackages((prevState) => {
      const newState = prevState.filter(
        (el) => !selectedRowsIds.some((id) => el._id === id)
      );
      setSelectedRowsIds([]);
      return [...newState];
    });
  };

  const modalSaveOrder = () => {
    if (packages.length > 0) {
      if (
        packages.some(
          (delivery) =>
            delivery.multiPackages !== delivery.scannedPackages?.length
        )
      ) {
        return openModal(MissingMultiPackagesModal, {
          deliveries: packages
        });
      }

      let content = '';
      if (
        packages.some((element) => element.packageSize !== packageSize) &&
        packageSize
      ) {
        content =
          'Are you sure you want to you want to update the package size ?';
      } else {
        content = intl.formatMessage({
          id: `hubs.receive_new_pickup.modal.${
            useWeights ? 'weight' : 'size'
          }_confirm`
        });
      }
      Modal.confirm({
        content: <div>{content}</div>,
        okButtonProps: { className: 'primaryButton' },
        cancelButtonProps: { className: 'secondaryButton' },
        onOk: async () => {
          handleSaveCurrentPackages();
        }
      });
    }
  };

  const handleWeightChange = ({ key, packageWeight }) => {
    const editedPackageIndex = packages.findIndex(({ _id }) => key === _id);
    const newPackages = [...packages];
    newPackages.splice(editedPackageIndex, 1, {
      ...packages[editedPackageIndex],
      packageWeight: packageWeight
    });
    setPackages(newPackages.reverse());
  };

  const components = {
    body: {
      cell: EditableCell
    }
  };

  const columns = packagesColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleWeightChange
      })
    };
  });

  const formatPackages = () => {
    let formattedObject = {};
    packages.forEach((item) => {
      if (formattedObject[item?.sender?.name]) {
        formattedObject[item?.sender?.name].deliveries.push(item);
      } else {
        formattedObject = {
          ...formattedObject,
          [item?.sender?.name]: {
            deliveries: [item]
          }
        };
      }
    });
    return formattedObject;
  };
  const addWrongScanLog = async (ids) => {
    const payload = {
      searchType: DELIVERIES_SCAN_TYPES.PICKUPS_RECEIVE_ORDER_SCAN,
      trackingNumbers: ids
    };
    try {
      await removeScannedDeliveries(payload);
    } catch (error) {
      notify(error.message);
    }
  };
  const handleOnRemoveReceived = (businessName, delivery) => {
    const trackingNumber = delivery.trackingNumber;
    if (selectedStar && firstMilePickupsCount) {
      if (pickups[businessName].originalDeliveriesCount > 0) {
        setPickups({
          ...pickups,
          [businessName]: {
            ...pickups[businessName],
            deliveriesCount: pickups[businessName].deliveriesCount + 1
          }
        });
      } else {
        setPickups({
          ...pickups,
          [businessName]: {
            ...pickups[businessName],
            deliveries: [...pickups[businessName].deliveries, delivery]
          }
        });
      }

      setFirstMilePickupCount(firstMilePickupsCount + 1);
    }

    removeFromScannedItem(delivery._id);
    addWrongScanLog([trackingNumber]);
  };

  const handleStarChange = (value) => {
    setSelectedStar(value);
    setPackages([]);
    if (!value) {
      setPickups({});
      setFirstMilePickupCount(0);
    }
    if (setIsMissedFirstMilePickups) {
      setIsMissedFirstMilePickups(false);
    }
  };

  const handleSendingMissedPickups = async () => {
    try {
      let formattedPayload = {
        pickups: []
      };
      Object.values(pickups).forEach((missedPickup) => {
        if (missedPickup.deliveries?.length) {
          let deliveriesArray = [];
          missedPickup.deliveries.forEach((delivery) => {
            deliveriesArray.push(delivery?.trackingNumber);
          });

          formattedPayload.pickups.push({
            pickupRequestId: missedPickup.pickupRequestId,
            trackingNumbers: deliveriesArray
          });
        }
        if (missedPickup.deliveriesCount) {
          formattedPayload.pickups.push({
            pickupRequestId: missedPickup.pickupRequestId,
            missedOrdersCount: missedPickup.deliveriesCount
          });
        }
      });
      await setMissedPickupsDeliveries(formattedPayload);
      setIsMissedFirstMilePickups(false);
      setPickups({});
      setFirstMilePickupCount(0);
    } catch (error) {
      notify(error.message);
    }
  };

  const handleOnCheckBoxChange = (e) => {
    setIsGroupByBusiness(e.target.checked);
  };

  const handleSizeChange = ({ value, businessName, delivery }) => {
    if (!value) {
      return;
    }
    const weightValue = value.split('KG')[0];

    if (isNaN(weightValue)) {
      notify('Please enter number not text');
      return;
    }
    setPackages((prev) => {
      return [
        ...prev.map((d) => {
          if (d.trackingNumber === delivery?.trackingNumber) {
            d.packageWeight = parseInt(weightValue);
          }
          return d;
        })
      ];
    });
  };

  return (
    <div className="br-receive-new-pickups__container">
      <div>
        <div className="br-receive-new-pickups__star-container">
          <div className="br-receive-new-pickups__star-select">
            <span className="body-medium"> Select Star</span>
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children
                  ?.join('')
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              getPopupContainer={(trigger) => trigger.parentElement}
              onChange={handleStarChange}
              allowClear
            >
              {stars.map(({ _id, profile: { firstName, lastName } }) => (
                <Select.Option key={_id} value={_id}>
                  {firstName} {lastName}
                </Select.Option>
              ))}
            </Select>
          </div>
          <Checkbox
            onChange={handleOnCheckBoxChange}
            value={isGroupByBusiness}
            defaultChecked
          >
            {intl.formatMessage({
              id: 'hubs.receive_new_pickup.group_by_business'
            })}
          </Checkbox>
        </div>
        {!!selectedStar && !!firstMilePickupsCount && (
          <div className="br-receive-new-pickups__star-pickups-count-container">
            <span>
              {intl.formatMessage(
                {
                  id: 'hubs.receive_new_pickup.star_no_of_pickups'
                },
                {
                  count: (
                    <span className="br-receive-new-hubs__first-mile-count-info">
                      {firstMilePickupsCount}
                    </span>
                  )
                }
              )}
            </span>
          </div>
        )}

        <PickupsTabActions
          checkTrackingNumberExistance={handleTrackingChange}
          checkSealNumberExistance={handleSealNumbersChange}
          packageSizes={allPricingPackageSize}
          useWeights={useWeights}
          showConfirmButton={true}
          confirmButtonText={intl.formatMessage({
            id: 'common.confirm_receiving'
          })}
          handleOnConfirmReceiveClick={modalSaveOrder}
          buttonDisabled={loading}
        />
        <FormGroup className="mt-3">
          <div>
            <h4>{packages.length} Packages Scanned.</h4>
          </div>
        </FormGroup>
      </div>
      <div className={styles.hubButtons}>
        {selectedRowsIds.length > 0 && (
          <Button
            className="primaryButton"
            disabled={loading}
            onClick={removeSelected}
          >
            Remove
          </Button>
        )}

        <Button
          className="primaryButton"
          disabled={loading}
          onClick={handleClearAll}
        >
          Clear
        </Button>
      </div>

      <hr />
      <Form form={form} component={false}>
        <NewSwappingContainer
          title1={`Pickups (${firstMilePickupsCount})`}
          title2={`Received (${packages.length})`}
          fmPickupsCount={firstMilePickupsCount}
          list={pickups}
          scannedList={isGroupByBusiness ? formatPackages() : packages}
          buttonText={intl.formatMessage({
            id: `hubs.packages_debrief.actions.confirm_receiving_pickups`
          })}
          showExtraDataInReceivedTable={true}
          useWeights={useWeights}
          handleOnRemoveReceived={handleOnRemoveReceived}
          isMissedFirstMilePickups={isMissedFirstMilePickups}
          groupByBusiness={isGroupByBusiness}
          handleSizeChange={handleSizeChange}
        />
      </Form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  playBeep: () => dispatch(beep()),
  playWarning: () => dispatch(warning())
});

const mapStateToProps = ({ hubs }) => {
  return {
    ...hubs
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ReceiveNewPickups));
