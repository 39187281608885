import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { getWrongZoneOrdersCount } from 'services/ops-control';

import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as MultiOrdersIcon } from 'assets/bosta-icons/multi-orders.svg';
import { ReactComponent as ClosedEye } from 'assets/bosta-icons/Eye-1.svg';

const WrongZoneTableHeader = ({ searchOptions }) => {
  const intl = useIntl();
  const [ordersCount, setOrdersCount] = useState(undefined);
  const [loadingOrdersCount, setLoadingOrdersCount] = useState(false);

  const handleGetOdersCount = useCallback(async () => {
    try {
      setLoadingOrdersCount(true);
      const { trackingNumber, currentHub, selectedDate } = searchOptions;
      const { count } = await getWrongZoneOrdersCount({
        selectedDate,
        currentHub,
        trackingNumber
      });
      setOrdersCount(count);
    } catch (e) {
      notify(e.message);
      setOrdersCount(undefined);
    } finally {
      setLoadingOrdersCount(false);
    }
  }, [searchOptions, setOrdersCount]);

  return (
    <>
      <span>
        <MultiOrdersIcon />{' '}
        {intl.formatMessage({ id: 'ops_control.wrong_zone.table.title' })}
      </span>
      {ordersCount !== undefined ? (
        ordersCount
      ) : (
        <BRButton
          label={intl.formatMessage({
            id: 'pickups.pickup_request.show_count'
          })}
          loading={loadingOrdersCount}
          prefixIcon={<ClosedEye />}
          onClick={handleGetOdersCount}
        />
      )}
    </>
  );
};

export default WrongZoneTableHeader;
