import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import axios from 'axios';
import dayjs from 'dayjs';

import { OpsControl } from 'contexts/ops-control.context';
import {
  exportWrongZoneOrders,
  getCurrentActiveDate,
  getWrongZoneOrders
} from 'services/ops-control';
import {
  WRONG_ZONE_COLUMNS,
  WRONG_ZONE_PAGE_OPTIONS
} from 'constants/ops-wrong-zone';
import { CURRENT_DATE_PARAMS } from 'constants/sorting-facility';
import { getPaginationCount } from 'utils/helpers';

import BRTable from 'components/BRTable/BRTable';
import { notify } from 'components/Notify/Notify';
import PendingTransitFilter from 'components/OpsControl/PendingTransit/PendingTransitFilter/PendingTransitFilter';
import WrongZoneTableHeader from './components/WrongZoneTableHeader/WrongZoneTableHeader';

import './WrongZone.less';

const WrongZone = () => {
  const intl = useIntl();
  const { allHubs } = useContext(OpsControl);

  const [loading, setLoading] = useState(false);
  const [searchOptions, setSearchOptions] = useState({
    trackingNumber: undefined,
    currentHub: undefined,
    selectedDate: undefined
  });

  const refreshTableRef = useRef(() => {});

  const updateSearchOption = useCallback((option) => {
    return (value) => {
      let updatedSearchOptions;

      setSearchOptions((curr) => {
        updatedSearchOptions = {
          ...curr,
          [option]: value
        };

        return updatedSearchOptions;
      });

      return updatedSearchOptions;
    };
  }, []);

  const handleHubChange = (hubValue) => {
    const updatedSearchOptions = updateSearchOption('currentHub')(hubValue);
    refreshTableRef.current({
      filterValues: {
        ...updatedSearchOptions,
        currentHub: hubValue
      }
    });
  };

  const handleDateChange = (dateValue) => {
    const formattedDate = dayjs(dateValue).format('YYYY-MM-DD');

    const updatedSearchOptions =
      updateSearchOption('selectedDate')(formattedDate);

    refreshTableRef.current({
      ...updatedSearchOptions,
      filterValues: {
        selectedDate: formattedDate
      }
    });
  };

  const listFunction = useCallback(
    async ({ page, limit, filterValues, searchInputText }) => {
      const updatedSearchOptions = {
        ...searchOptions,
        ...filterValues,
        trackingNumber: searchInputText
      };

      const result = {
        list: [],
        total: 0
      };

      if (!updatedSearchOptions.selectedDate) {
        return result;
      }

      setLoading(true);
      try {
        const { data } = await getWrongZoneOrders({
          page,
          limit,
          ...updatedSearchOptions
        });

        updateSearchOption('trackingNumber')(searchInputText);

        result.list = data || [];
        result.total = getPaginationCount({
          page,
          result: result.list,
          pageSize: limit
        });
      } catch (e) {
        notify(e.message);
      } finally {
        setLoading(false);
      }
      return result;
    },
    [searchOptions, updateSearchOption]
  );

  const handleExportListFile = useCallback(async () => {
    setLoading(true);
    try {
      const { success, message } = await exportWrongZoneOrders(searchOptions);
      notify(message, success ? 'success' : 'error');
    } catch (e) {
      notify(e.message);
    } finally {
      setLoading(false);
    }
  }, [searchOptions]);

  const initializeSelectedDate = useCallback(
    async (cancelToken = undefined) => {
      const payload = { pageName: CURRENT_DATE_PARAMS.WRONG_ZONE };
      setLoading(true);
      try {
        const {
          data: { currentDate }
        } = await getCurrentActiveDate(payload, { cancelToken });
        updateSearchOption('selectedDate')(currentDate);

        refreshTableRef.current({
          filterValues: {
            selectedDate: currentDate
          }
        });
      } catch (e) {
        if (e.status !== -1) {
          notify(e.message);
        }
      } finally {
        setLoading(false);
      }
    },
    [updateSearchOption]
  );

  const shareMethods = (refreshMethod) => {
    refreshTableRef.current = refreshMethod;
  };

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    initializeSelectedDate(cancelTokenSource.token);
    return () => cancelTokenSource.cancel();
  }, [initializeSelectedDate]);

  return (
    <div className="br-ops-control-wrong-zone">
      <PendingTransitFilter
        loading={loading}
        intl={intl}
        allHubs={allHubs}
        currentHub={searchOptions.currentHub}
        selectedDate={searchOptions.selectedDate}
        setCurrentHub={updateSearchOption('currentHub')}
        setSelectedDate={updateSearchOption('selectedDate')}
        handleHubChange={handleHubChange}
        changeDateValue={handleDateChange}
        hubSelectionPlaceholder={intl.formatMessage({
          id: 'ops_control.wrong_zone.filters.hubs'
        })}
      />
      <BRTable
        className="hide-pagination"
        rowKey="id"
        isLoading={loading}
        title={<WrongZoneTableHeader searchOptions={searchOptions} />}
        searchPlaceholder={intl.formatMessage({
          id: 'ops_control.wrong_zone.filters.tracking_number'
        })}
        onSearchInputChange={updateSearchOption('trackingNumber')}
        listFunction={listFunction}
        exportListFileFunction={handleExportListFile}
        columns={WRONG_ZONE_COLUMNS}
        pageSizeOptions={WRONG_ZONE_PAGE_OPTIONS}
        shareMethods={shareMethods}
        hideFilterButton
        showSearch
      />
    </div>
  );
};

export default WrongZone;
