import React, { useState, useEffect, useCallback, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button, Tabs, Dropdown, Menu, Tag, Form, InputNumber } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { debounce, isNil } from 'lodash';

import { printAirwaybill } from 'services/shipments';
import {
  receivePackagesAtHub,
  checkOrderExistance,
  linkDelivery,
  scanDeliveries,
  setConfirmedPickupsDeliveries
} from 'services/hubs';
import { getAllPriceSize } from 'services/priceTiers';
import {
  ACTION_TYPES,
  ACTION_TYPES_TAG_COLOR,
  LAST_MILE_PICKUPS_CODES,
  MAP_PACKAGE_SIZES,
  SOHO_PICKUP,
  RECEIVED_AT_WAREHOUSE_CODE,
  DELIVERIES_SCAN_TYPES
} from 'constants/hubs';
import { DELIVERY_TYPES } from 'constants/Deliveries';
import { TAB_KEYS } from 'constants/debrief';
import { IS_SAUDI } from 'constants/helper';
import { isRepackAction } from 'common/countries/countries-mapping';

import { openModal } from 'utils/modal';
import { downloadAsPdf } from 'utils/download';
import {
  getDeliveryPendingActions,
  isInvalidMultiPackageScan,
  showNotification
} from 'utils/hubs';
import { isDefaultBostaCourier, isDSPVendor, numberRegex } from 'utils/helpers';
import Sounds from 'common/Sounds';
import { getDeliveryType } from 'utils/deliveries';

import NewSwappingContainer from '../NewSwappingContainer/NewSwappingContainer';
import { receivePickupsConfirmation } from 'components/Hub/PackagesDebrief/PackagesDebriefTabs/TableActionsModals/TableActionsModals';
import { notify } from 'components/Notify/Notify';
import {
  DeleteModal,
  CreateTicketModal,
  PickupRequestModal
} from 'components/Hub/PackagesDebrief/PackagesDebriefTabs/TableActionsModals/TableActionsModals';
import PrintAwbModal from 'components/Hub/PackagesDebrief/PrintAwbModal/PrintAwbModal';
import SwappingTables from 'components/Hub/PackagesDebrief/PackagesDebriefTabs/SwappingTables/SwappingTables';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRFilter from 'components/BRFilter/BRFilter';
import { filterTypes } from 'components/BRFilter/BRFilter';
import deliveryStateFormatter from 'containers/Deliveries/common/DeliveryStateFormatter';
import PickupsTabActions from 'components/Hub/PackagesDebrief/PackagesDebriefTabs/PickupsTabActions/PickupsTabActions';
import BRImage from 'components/BRImage/BRImage';
import MissingMultiPackagesModal from 'components/MultiPackages/components/MissingMultiPackagesModal/MissingMultiPackagesModal';
import MultiPackagesCount from 'components/MultiPackages/components/MultiPackagesCount/MultiPackagesCount';
import BRButton from 'components/BRButton/BRButton';
import ViewImagesModal from './ViewImagesModal/ViewImagesModal';

import { ReactComponent as SeeMore } from 'assets/bosta-icons/Eye.svg';

import './PickupsTab.less';

const filterOptions = [
  {
    key: 'types',
    header: 'Order Types',
    lookup: DELIVERY_TYPES,
    type: filterTypes.LOOKUP_SINGLE
  }
];

const useWeights = IS_SAUDI;

const { beep } = Sounds;

const PickupsTab = ({
  starData,
  intl,
  getPickups,
  hubName,
  handleConfirmReceiving,
  setIsConfirmReceivingDisabled,
  isMissedFirstMilePickups,
  setFmMissedPickupsInfo,
  setPackageDebriefpageLoading
}) => {
  const [selectedTab, setSelectedTab] = useState(
    isDefaultBostaCourier(['VENDOR_MANAGER', 'HUB_LEADER', 'WAREHOUSE_CLERK'])
      ? '0'
      : '1'
  );
  const [allPricingPackageSize, setAllPricingPackageSize] = useState([]);
  const [lastMilePickups, setLastMilePickups] = useState([]);
  const [firstMilePickups, setFirstMilePickups] = useState([]);
  const [selectedSize, setSelectedSize] = useState({
    size: null,
    mappedSize: null
  });
  const [isFilterUsed, setIsFilterUsed] = useState(false);
  const [filterItems, setFilterItems] = useState([]);
  const [scannedFirstMile, setScannedFirstMile] = useState([]);
  const [scannedLastMile, setScannedLastMile] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [businessDeliveries, setBusinessDeliveries] = useState({});
  const [firstMilePickupsCount, setFirstMilePickupsCount] = useState(0);
  const [scannedFirstMilePickupsCount, setScannedFirstMilePickupsCount] =
    useState(0);

  const trackingNumberRef = useRef('');
  const packageSizeRef = useRef('');

  const [form] = Form.useForm();

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex]
      });
    };

    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleWeightChange({ ...record, ...values });
      } catch {
        toggleEdit();
      }
    };

    let childNode = children;

    if (editable) {
      childNode = editing ? (
        <Form.Item
          name={dataIndex}
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: 'hubs.receive_new_pickup.weight_required'
              })
            }
          ]}
        >
          <InputNumber
            className="br-receive-new-pickups__table-input"
            placeholder={intl.formatMessage({
              id: 'hubs.receive_new_pickup.pickups_tab_actions.weight_placeholder'
            })}
            ref={inputRef}
            min={1}
            step={1}
            precision={0}
            onPressEnter={save}
            onBlur={save}
          />
        </Form.Item>
      ) : (
        <div className="editable-cell-value-wrap" onClick={toggleEdit}>
          {children}
        </div>
      );
    }

    return <td {...restProps}>{childNode}</td>;
  };

  const components = {
    body: {
      cell: EditableCell
    }
  };

  const stateLocalized = intl.formatMessage({
    id: `hubs.packages_debrief.columns.state`
  });

  const sizeLocalized = intl.formatMessage({
    id: `hubs.packages_debrief.columns.size`
  });

  const typeLocalized = intl.formatMessage({
    id: `hubs.packages_debrief.columns.type`
  });

  const pickupLocalized = intl.formatMessage({
    id: `hubs.packages_debrief.columns.pickup`
  });

  const packageWeightLocalized = intl.formatMessage({
    id: `shipments.new_order.order_details.package_weight`
  });

  const getPackagesSizes = useCallback(async () => {
    try {
      setIsLoading(true);
      const priceSize = await getAllPriceSize();
      setAllPricingPackageSize(
        priceSize?.data?.sizes.map((item) => {
          const oldPricing = ['Normal', 'White Bag'].indexOf(item.name);
          return {
            ...item,
            name:
              item?.alias !== item.name
                ? `${item.alias} (${item.name})`
                : item.name,
            value: item.name,
            oldPricing: oldPricing === -1 ? 0 : oldPricing,
            dimmed: ['Large', 'X-Large'].includes(item.name)
          };
        })
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      notify(error.message);
    }
  }, []);

  const handleWeightChange = ({ _id: key, packageWeight }) => {
    const packages = selectedTab === '0' ? scannedFirstMile : scannedLastMile;
    const editedPackageIndex = packages.findIndex(({ _id }) => key === _id);
    const newPackages = [...packages];
    newPackages.splice(editedPackageIndex, 1, {
      ...packages[editedPackageIndex],
      packageWeight: packageWeight
    });
    selectedTab === '0'
      ? setScannedFirstMile(newPackages)
      : setScannedLastMile(newPackages);
  };

  useEffect(() => {
    !useWeights && getPackagesSizes();
  }, []);

  useEffect(() => {
    setLastMilePickups(
      starData?.deliveries.filter((el) =>
        LAST_MILE_PICKUPS_CODES.includes(el.state.code)
      )
    );
    setFirstMilePickups(formatPickupsData(starData?.pickups));
    setScannedFirstMile({});
    setScannedLastMile([]);
  }, [starData]);

  useEffect(() => {
    if (Object.values(firstMilePickups).length) {
      let count = 0;
      Object.values(firstMilePickups).forEach((record) => {
        count += record?.deliveriesCount
          ? record?.deliveriesCount
          : record?.deliveries?.length;
      });
      setFirstMilePickupsCount(count);
    } else {
      setFirstMilePickupsCount(0);
    }
    setFmMissedPickupsInfo(firstMilePickups);
  }, [firstMilePickups]);

  useEffect(() => {
    if (Object.values(scannedFirstMile).length) {
      let count = 0;
      Object.values(scannedFirstMile).forEach((record) => {
        count += record?.deliveries?.length || record?.deliveriesCount;
      });
      setScannedFirstMilePickupsCount(count);
    } else {
      setScannedFirstMilePickupsCount(0);
    }
  }, [scannedFirstMile]);

  const formatPickupsData = (pickups = []) => {
    let formattedBusinessObject = {};
    pickups.forEach((pickup) => {
      if (pickup.type !== SOHO_PICKUP) {
        if (formattedBusinessObject[pickup?.business?.name]) {
          formattedBusinessObject[pickup?.business?.name] = {
            businessId: pickup?.business?._id,
            pickupRequestId: pickup?._id,
            deliveries: [
              ...formattedBusinessObject[pickup?.business?.name].deliveries,
              ...pickup.deliveries.filter(
                (el) =>
                  !RECEIVED_AT_WAREHOUSE_CODE.includes(el.state.code) &&
                  pickup.type !== SOHO_PICKUP
              )
            ],
            deliveriesCount:
              pickup?.pickupsCounter - (pickup?.receivedOrdersCount ?? 0) || 0,
            originalDeliveriesCount: pickup?.pickupsCounter || 0
          };
        } else {
          formattedBusinessObject[pickup?.business?.name] = {
            businessId: pickup?.business?._id,
            pickupRequestId: pickup?._id,
            deliveries: pickup.deliveries.filter(
              (el) =>
                !RECEIVED_AT_WAREHOUSE_CODE.includes(el.state.code) &&
                pickup.type !== SOHO_PICKUP
            ),
            deliveriesCount:
              pickup?.pickupsCounter - (pickup?.receivedOrdersCount ?? 0) || 0,
            originalDeliveriesCount: pickup?.pickupsCounter || 0
          };
        }
      }
    });
    // setBusinessDeliveries(formattedBusinessObject);
    return formattedBusinessObject;
  };

  const preSubmissionValidation = () => {
    const firstMileDeliveries = getScannedFirstMileDeliveries();
    const firstMileContainsMissingMultiPackageScans = firstMileDeliveries.some(
      (delivery) => delivery.multiPackages !== delivery.scannedPackages?.length
    );
    const lastMileContainsMissingMultiPackageScans = scannedLastMile.some(
      (delivery) => delivery.multiPackages !== delivery.scannedPackages?.length
    );

    const allDeliveries = [...firstMileDeliveries, ...scannedLastMile];

    if (
      firstMileContainsMissingMultiPackageScans ||
      lastMileContainsMissingMultiPackageScans
    ) {
      return openModal(MissingMultiPackagesModal, {
        deliveries: allDeliveries,
        onConfirm: handleConfirmReceivingOrders
      });
    }

    handleConfirmReceivingOrders();
  };

  useEffect(() => {
    if (handleConfirmReceiving) {
      handleConfirmReceiving[TAB_KEYS.PICKUPS] = preSubmissionValidation;
    }
  });

  useEffect(() => {
    setIsConfirmReceivingDisabled(
      isLoading ||
        (!Object.keys(scannedFirstMile)?.length && !scannedLastMile?.length)
    );
  }, [isLoading, scannedFirstMile, scannedLastMile]);

  const handleFilterSearch = () => {
    setIsFilterUsed(false);
    selectedTab === '0'
      ? setFilterItems(firstMilePickups)
      : setFilterItems(lastMilePickups);
  };

  const onChangeDeliveryType = async (value) => {
    const { types } = value;
    if (value) {
      setIsFilterUsed(true);
      selectedTab !== '0'
        ? setFilterItems(
            lastMilePickups.filter((el) => types.includes(el.type))
          )
        : setFilterItems(
            firstMilePickups.filter((el) => types.includes(el.type))
          );
    }
  };

  const handleAwbClick = (id, record) => {
    const { trackingNumber } = record;

    const printAwb = () => handlePrintAWB(id, trackingNumber);

    if (isRepackAction()) {
      openModal(PrintAwbModal, {
        onSuccess: printAwb,
        item: record,
        intl
      });
    } else {
      printAwb();
    }
  };

  const hasImages = (extraData) => {
    return (
      extraData?.starProofOfReturnedPackages ||
      extraData?.businessProofOfReturnedPackages
    );
  };

  const openPackageImages = (extraData, record) => {
    openModal(ViewImagesModal, {
      record: record,
      item: extraData,
      intl
    });
  };
  const lastMileReceivedTableColumns = [
    {
      title: intl.formatMessage({
        id: `hubs.packages_debrief.columns.tracking_number`
      }),
      dataIndex: '',
      width: 70,
      render: (item) => (
        <Link target="_blank" to={`/deliveries/${item._id}/details`}>
          {item.trackingNumber}
        </Link>
      )
    },
    {
      title: 'No. of Packages',
      dataIndex: 'scannedPackages',
      render: (scannedPackages, record) =>
        scannedPackages?.length ? (
          <MultiPackagesCount delivery={record} />
        ) : (
          'N/A'
        )
    },
    {
      title: `${useWeights ? packageWeightLocalized : sizeLocalized}`,
      dataIndex: `${useWeights ? 'packageWeight' : 'pricingPackageSize'}`,
      render: (value) => (
        <span>
          {useWeights
            ? intl.formatMessage(
                { id: 'common.weight_kg' },
                {
                  weight: value
                }
              )
            : value?.name}
        </span>
      ),
      editable: useWeights,
      width: 100
    },
    {
      title: selectedTab === '0' ? pickupLocalized : typeLocalized,
      dataIndex: '',
      width: 80,
      render: (item) =>
        selectedTab === '0'
          ? `${item?.pickupRequestId || item?.puid || ''} ${
              item?.sender?.name || item?.business?.name
            }`
          : getDeliveryType(item)
    },
    {
      title: stateLocalized,
      // dataIndex: ['state', 'value'],
      width: 90,
      render: (item) => deliveryStateFormatter.getStateName(item)
    },
    {
      title: intl.formatMessage({
        id: `hubs.packages_debrief.columns.record_action`
      }),
      dataIndex: '_id',
      width: 90,
      render: (id, record) => (
        <Button onClick={() => handleAwbClick(id, record)}>
          {intl.formatMessage({
            id: `hubs.packages_debrief.actions.print_awb`
          })}
        </Button>
      )
    },
    {
      title: intl.formatMessage({
        id: `hubs.packages_debrief.columns.type`
      }),
      dataIndex: 'actionType',
      render: (actionType) => (
        <Tag color={ACTION_TYPES_TAG_COLOR[actionType]}>
          {ACTION_TYPES[actionType]}
        </Tag>
      )
    },
    {
      title: intl.formatMessage({
        id: `hubs.packages_debrief.columns.image_thumbnail`
      }),
      dataIndex: 'extraData',
      align: 'center',
      width: 90,
      render: (extraData, record) => (
        <BRButton
          disabled={!(() => hasImages(extraData))}
          onClick={() => openPackageImages(extraData, record)}
          className={'br-packages-debrief__see-more-btn'}
          label={intl.formatMessage({ id: 'common.view' })}
          prefixIcon={<SeeMore />}
        />
      )
    }
  ];

  const lastMilePickupsColumns = [
    {
      title: intl.formatMessage({
        id: `hubs.packages_debrief.columns.tracking_number`
      }),
      dataIndex: '',
      width: 10,
      render: (item) => (
        <>
          <Link target="_blank" to={`/deliveries/${item?._id}/details`}>
            {item?.trackingNumber}
          </Link>
        </>
      )
    },
    {
      title: selectedTab === '0' ? pickupLocalized : typeLocalized,
      dataIndex: '',
      width: 120,
      render: (item) =>
        selectedTab === '0'
          ? `${item?.pickupRequestId || ''} ${item?.sender?.name}`
          : getDeliveryType(item)
    },
    {
      title: stateLocalized,
      // dataIndex: ['state', 'value'],
      render: (item) => deliveryStateFormatter.getStateName(item),
      width: 120
    }
  ];

  const tabs = [
    isDefaultBostaCourier([
      'VENDOR_MANAGER',
      'HUB_LEADER',
      'WAREHOUSE_CLERK'
    ]) || isDSPVendor()
      ? {
          tabName: intl.formatMessage(
            {
              id: `hubs.packages_debrief.tabs.first_mile`
            },
            { length: firstMilePickupsCount }
          )
        }
      : null,
    {
      tabName: intl.formatMessage(
        {
          id: `hubs.packages_debrief.tabs.last_mile`
        },
        {
          length: starData?.deliveries.filter((el) =>
            LAST_MILE_PICKUPS_CODES.includes(el.state.code)
          ).length
        }
      )
    }
  ];

  const formatEditableColumns = (columns) => {
    return columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title
        })
      };
    });
  };

  const getTrackingNumberIndexFirstMile = (
    trackingNumber,
    isScannedObject = false
  ) => {
    let shouldSkip = false;
    let result = -1;
    Object.values(
      isScannedObject ? scannedFirstMile : firstMilePickups
    ).forEach((perBusinessInfo) => {
      if (shouldSkip) {
        return;
      }
      const index = perBusinessInfo.deliveries.findIndex(
        (el) =>
          el.trackingNumber === trackingNumber ||
          el.barcodeRef === trackingNumber
      );
      if (index !== -1) {
        result = index;
        shouldSkip = true;
      }
    });
    return result;
  };

  const getBusinessKeyName = (trackingNumber) => {
    let shouldSkip = false;
    let result = '';
    Object.keys(firstMilePickups).forEach((businessName) => {
      if (shouldSkip) {
        return;
      }
      const index = firstMilePickups[businessName].deliveries.findIndex(
        (el) =>
          el.trackingNumber === trackingNumber ||
          el.barcodeRef === trackingNumber
      );
      if (index !== -1) {
        result = businessName;
        shouldSkip = true;
      }
    });
    return result;
  };

  const formatScannedFirstMileObject = (
    trackingNumber,
    firstMileScannedBusinessName,
    delivery,
    isCounterChange = false,
    extraDetails = {}
  ) => {
    let newFormattedObject = {};
    if (scannedFirstMile[firstMileScannedBusinessName]) {
      newFormattedObject = {
        ...scannedFirstMile,
        [firstMileScannedBusinessName]: {
          ...scannedFirstMile[firstMileScannedBusinessName],
          ...(isCounterChange
            ? {
                deliveriesCount:
                  scannedFirstMile[firstMileScannedBusinessName]
                    .deliveriesCount + 1
              }
            : {
                deliveries: [
                  { ...delivery, ...extraDetails },
                  ...scannedFirstMile[firstMileScannedBusinessName].deliveries
                ]
              })
        }
      };
    } else {
      newFormattedObject = {
        ...scannedFirstMile,
        [firstMileScannedBusinessName]: {
          ...(isCounterChange
            ? { deliveriesCount: 1 }
            : { deliveries: [{ ...delivery, ...extraDetails }] })
        }
      };
    }
    return newFormattedObject;
  };

  const isValidMultiPackageScan = ({ trackingNumber, packageNumber }) => {
    if (selectedTab === '0') {
      for (const [businessName, scannedData] of Object.entries(
        scannedFirstMile
      )) {
        const deliveryIndex = scannedData.deliveries.findIndex(
          (delivery) => delivery.trackingNumber === trackingNumber
        );

        if (deliveryIndex > -1) {
          const deliveryDetails = scannedData.deliveries[deliveryIndex];
          if (
            isInvalidMultiPackageScan({
              delivery: deliveryDetails,
              packageNumber
            })
          ) {
            return false;
          }

          return { deliveryIndex, businessName };
        }
      }
    } else {
      const deliveryIndex = scannedLastMile.findIndex(
        (delivery) => delivery.trackingNumber === trackingNumber
      );
      if (deliveryIndex > -1) {
        if (
          isInvalidMultiPackageScan({
            delivery: scannedLastMile[deliveryIndex],
            packageNumber
          })
        ) {
          return false;
        }

        return { deliveryIndex };
      }
    }

    return false;
  };

  const checkTrackingNumberExistance = useCallback(
    debounce(
      async (
        value,
        selectedScanningType,
        resetInputs,
        scanType,
        packageWeight
      ) => {
        const [trackingNumber, packageNumber] = value.trim().split('/');
        const parsedPackageNumber = parseInt(packageNumber);

        if (trackingNumber.trim().length >= 6) {
          if (
            !isNil(packageNumber) &&
            (!numberRegex.test(parsedPackageNumber) ||
              parsedPackageNumber === 0)
          ) {
            return notify(
              intl.formatMessage({
                id: 'hubs.receive_new_pickup.invalid_multipackage'
              }),
              'error',
              true
            );
          }

          let firstMileScannedBusinessName =
            selectedTab === '1' ? '' : getBusinessKeyName(trackingNumber);
          const isTrackingNumberFound =
            selectedTab === '1'
              ? lastMilePickups.findIndex(
                  (el) =>
                    el.trackingNumber === trackingNumber ||
                    el.barcodeRef === trackingNumber
                )
              : getTrackingNumberIndexFirstMile(trackingNumber);
          const oldPricing = ['Normal', 'White Bag'].indexOf(
            selectedScanningType
          );
          let actionType;
          if (isTrackingNumberFound > -1) {
            let deliveryDetails = [];
            let deliveryPackageWeight;
            try {
              const payload = {
                trackingNumbers: [trackingNumber],
                searchType: DELIVERIES_SCAN_TYPES.RECEIVE_ORDER_SCAN
              };

              const { data } = await scanDeliveries(payload);
              actionType = getDeliveryPendingActions(data[0]);
              actionType && showNotification(actionType, data[0]);
              deliveryDetails = data[0];
              deliveryPackageWeight = data[0]?.specs?.weight;

              if (
                packageNumber > data[0]?.multiPackages ||
                (data[0]?.multiPackages && !packageNumber)
              ) {
                notify(
                  intl.formatMessage({
                    id: 'hubs.receive_new_pickup.invalid_multipackage'
                  }),
                  'error',
                  true
                );
                return;
              }
            } catch (error) {
              notify(error.message);
            }

            let modifiedScannedItem = {};

            selectedTab === '0'
              ? setFirstMilePickups({
                  ...firstMilePickups,
                  [firstMileScannedBusinessName]: {
                    ...firstMilePickups[firstMileScannedBusinessName],
                    deliveries: firstMilePickups[
                      firstMileScannedBusinessName
                    ].deliveries.filter(
                      (el) =>
                        el.trackingNumber !== trackingNumber &&
                        el.barcodeRef !== trackingNumber
                    )
                  }
                })
              : setLastMilePickups((prev) => {
                  const temp = prev.filter(
                    (el) =>
                      el.trackingNumber !== trackingNumber &&
                      el.barcodeRef !== trackingNumber
                  );
                  return [...temp];
                });

            modifiedScannedItem = {
              ...lastMilePickups[isTrackingNumberFound],
              pricingPackageSize: {
                name: selectedScanningType || 'Normal'
              },
              packageSize: oldPricing === -1 ? 0 : oldPricing,
              scanType,
              actionType,
              packageWeight: Math.ceil(deliveryPackageWeight) || packageWeight,
              ...(parsedPackageNumber && {
                scannedPackages: [parsedPackageNumber]
              })
            };

            selectedTab === '0'
              ? setScannedFirstMile(
                  formatScannedFirstMileObject(
                    trackingNumber,
                    firstMileScannedBusinessName,
                    deliveryDetails,
                    false,
                    {
                      pricingPackageSize: {
                        name: selectedScanningType || 'Normal'
                      },
                      packageSize: oldPricing === -1 ? 0 : oldPricing,
                      scanType,
                      actionType,
                      packageWeight:
                        Math.ceil(deliveryPackageWeight) || packageWeight,
                      pickupRequestId:
                        firstMilePickups[firstMileScannedBusinessName]
                          ?.pickupRequestId,
                      ...(parsedPackageNumber && {
                        scannedPackages: [parsedPackageNumber]
                      })
                    }
                  )
                )
              : setScannedLastMile((prev) => [modifiedScannedItem, ...prev]);
            beep();
          } else {
            if (selectedTab === '0') {
              const scannedDeliveryIndex = getTrackingNumberIndexFirstMile(
                trackingNumber,
                true
              );

              if (scannedDeliveryIndex > -1) {
                const isValidScan = isValidMultiPackageScan({
                  trackingNumber,
                  packageNumber: parsedPackageNumber
                });

                if (!isValidScan) {
                  notify(
                    `The package with tracking number ${trackingNumber} is already scanned!`,
                    'error',
                    true
                  );
                } else {
                  const { deliveryIndex, businessName } = isValidScan;
                  const delivery =
                    scannedFirstMile[businessName].deliveries[deliveryIndex];

                  if (
                    packageNumber > delivery?.multiPackages ||
                    (delivery?.multiPackages && !packageNumber)
                  ) {
                    notify(
                      intl.formatMessage({
                        id: 'hubs.receive_new_pickup.invalid_multipackage'
                      }),
                      'error',
                      true
                    );
                    return;
                  }

                  setScannedFirstMile((prevScannedFirstMile) => {
                    const updatedScannedFirstMile = { ...prevScannedFirstMile };
                    updatedScannedFirstMile[businessName].deliveries[
                      deliveryIndex
                    ].scannedPackages.push(parsedPackageNumber);

                    return updatedScannedFirstMile;
                  });
                  beep();
                }
              } else {
                try {
                  const data = await checkOrderExistance({ trackingNumber });
                  if (data) {
                    if (
                      packageNumber > data?.multiPackages ||
                      (data?.multiPackages && !packageNumber)
                    ) {
                      notify(
                        intl.formatMessage({
                          id: 'hubs.receive_new_pickup.invalid_multipackage'
                        }),
                        'error',
                        true
                      );
                      return;
                    }

                    let deliveryBusinessName = '';
                    const pickup = starData.pickups.filter(
                      (pickup) =>
                        data.sender?._id === pickup.business?._id &&
                        pickup?.type === 'Business Pickup'
                    )[0];
                    if (pickup) {
                      deliveryBusinessName = data?.sender?.name;
                      await linkDelivery(data._id, {
                        pickupId: pickup._id
                      });
                    } else {
                      notify(
                        "Cannot receive this order. It doesn't belong to any of the star pickup requests.",
                        'error',
                        true
                      );
                      return;
                    }
                    actionType = getDeliveryPendingActions(data);
                    actionType && showNotification(actionType, data);
                    const deliveryPackageWeight = data?.specs?.weight;
                    setScannedFirstMile(
                      formatScannedFirstMileObject(
                        data?.trackingNumber,
                        deliveryBusinessName,
                        data,
                        false,
                        {
                          pricingPackageSize: {
                            name: selectedScanningType || 'Normal'
                          },
                          packageSize: oldPricing === -1 ? 0 : oldPricing,
                          scanType,
                          actionType,
                          packageWeight:
                            Math.ceil(deliveryPackageWeight) || packageWeight,
                          pickupRequestId: pickup?._id,
                          ...(parsedPackageNumber && {
                            scannedPackages: [parsedPackageNumber]
                          })
                        }
                      )
                    );
                    setFirstMilePickups({
                      ...firstMilePickups,
                      [deliveryBusinessName]: {
                        ...firstMilePickups[deliveryBusinessName],
                        deliveriesCount:
                          firstMilePickups[deliveryBusinessName]
                            .deliveriesCount - 1
                      }
                    });
                    beep();
                    // trackingNumberRef.current.handleReset(() => {});
                  }
                } catch (error) {
                  notify(error.message, 'error', true);
                }
              }
            } else {
              const isValidScan = isValidMultiPackageScan({
                trackingNumber,
                packageNumber: parsedPackageNumber
              });

              if (!isValidScan) {
                notify(
                  `The package with tracking number ${trackingNumber} is not listed!`,
                  'error',
                  true
                );
              } else {
                const { deliveryIndex } = isValidScan;
                const newScannedDeliveries = [...scannedLastMile];

                newScannedDeliveries[deliveryIndex].scannedPackages.push(
                  parsedPackageNumber
                );

                setScannedLastMile(newScannedDeliveries);
                beep();
              }
            }
          }
        }
        if (resetInputs) {
          resetInputs(scanType === 'Selected');
        }
      },
      500
    ),
    [scannedLastMile, scannedFirstMile, selectedTab, firstMilePickups]
  );

  const handlePackageSizeGroups = (itemsArray) => {
    let SIZES = {
      HEAVY_BULKY: [],
      LIGHT_BULKY: [],
      WHITE_BAG: [],
      X_LARGE: [],
      LARGE: [],
      NORMAL: []
    };

    itemsArray.map((item) => {
      const oldPricing = ['Normal', 'White Bag'].indexOf(
        item?.pricingPackageSize?.name
      );
      switch (item?.pricingPackageSize?.name) {
        case 'Heavy Bulky':
          return SIZES.HEAVY_BULKY.push({
            ...item,
            pricingPackageSize: MAP_PACKAGE_SIZES.HEAVY_BULKY,
            packageSize: oldPricing === -1 ? 0 : oldPricing
          });
        case 'Light Bulky':
          return SIZES.LIGHT_BULKY.push({
            ...item,
            pricingPackageSize: MAP_PACKAGE_SIZES.LIGHT_BULKY,
            packageSize: oldPricing === -1 ? 0 : oldPricing
          });
        case 'White Bag':
          return SIZES.WHITE_BAG.push({
            ...item,
            pricingPackageSize: MAP_PACKAGE_SIZES.WHITE_BAG,
            packageSize: oldPricing === -1 ? 0 : oldPricing
          });
        case 'X-Large':
          return SIZES.X_LARGE.push({
            ...item,
            pricingPackageSize: MAP_PACKAGE_SIZES.X_LARGE,
            packageSize: oldPricing === -1 ? 0 : oldPricing
          });
        case 'Large':
          return SIZES.LARGE.push({
            ...item,
            pricingPackageSize: MAP_PACKAGE_SIZES.LARGE,
            packageSize: oldPricing === -1 ? 0 : oldPricing
          });
        case 'Normal':
          return SIZES.NORMAL.push({
            ...item,
            pricingPackageSize: MAP_PACKAGE_SIZES.NORMAL,
            packageSize: oldPricing === -1 ? 0 : oldPricing
          });
        default:
          return;
      }
    });
    return SIZES;
  };

  const getScannedFirstMileDeliveries = () => {
    let firstMileFormattedDeliveries = [];
    Object.values(scannedFirstMile).forEach((record) => {
      if (record.deliveries?.length) {
        firstMileFormattedDeliveries.push(...record.deliveries);
      }
    });
    return firstMileFormattedDeliveries;
  };

  const formatReceivedOrders = () => {
    let formattedPayload = {
      pickups: []
    };
    Object.values(scannedFirstMile).map((business) => {
      if (business.deliveries?.length) {
        formattedPayload.pickups.push({
          pickupRequestId: business.deliveries[0].pickupRequestId,
          receivedOrdersCount: business.deliveries?.length
        });
      }
    });
    return formattedPayload;
  };

  const handleConfirmReceivingOrders = async () => {
    try {
      setIsLoading(true);
      const obj = handlePackageSizeGroups(
        selectedTab === '0' ? getScannedFirstMileDeliveries() : scannedLastMile
      );
      let messages = [];
      let deliveries = [];
      Object.entries(obj).forEach(async ([key, value]) => {
        if (value.length) {
          deliveries.push(...value);
          messages.push(
            `${value.length} pickups${useWeights ? '' : `,size: '${key}'`}`
          );
        }
      });
      openModal(receivePickupsConfirmation, {
        messages,
        onSuccess: async () => {
          setPackageDebriefpageLoading(true);
          try {
            const receivePayload = {
              updateType: 'receiveMany',
              deliveries: deliveries.map(
                ({
                  _id,
                  packageSize,
                  pricingPackageSize,
                  scanType,
                  packageWeight
                }) => {
                  return {
                    _id,
                    packageSize,
                    pricingPackageSize,
                    scanType,
                    packageWeight
                  };
                }
              )
            };
            const result = await receivePackagesAtHub(receivePayload);
            if (result?.failedDeliveries) {
              result?.failedDeliveries.length === 1
                ? notify(
                    `Delivery with TN: ${result?.failedDeliveries.join(
                      ', '
                    )} failed to be received.`
                  )
                : notify(
                    `Deliveries with TNs: ${result?.failedDeliveries.join(
                      ', '
                    )} failed to be received.`
                  );
            } else {
              notify('Deliveries received successfully!', 'success');
            }
            const payload = formatReceivedOrders();
            if (payload?.pickups?.length) {
              await setConfirmedPickupsDeliveries(payload);
            }
          } catch (error) {
            notify(error.message);
          }
          setPackageDebriefpageLoading(true);
          await getPickups();
        }
      });
    } catch (error) {
      notify(error.message, 'error', true);
    }
    setIsLoading(false);
  };

  const handleRemoveFromRecievedTable = (record) => {
    const { trackingNumber } = record;
    openModal(DeleteModal, {
      onSuccess: () => {
        if (selectedTab === '0') {
          setScannedFirstMile((prevState) =>
            prevState.filter((el) => el.trackingNumber !== trackingNumber)
          );
          setFirstMilePickups((prev) => {
            const temp = prev.filter(
              (el) => el.trackingNumber !== trackingNumber
            );
            return [...temp, record];
          });
        } else {
          setScannedLastMile((prevState) =>
            prevState.filter((el) => el.trackingNumber !== trackingNumber)
          );
          setLastMilePickups((prev) => {
            const temp = prev.filter(
              (el) => el.trackingNumber !== trackingNumber
            );
            return [...temp, record];
          });
        }
      }
    });
  };

  const handlePrintAWB = async (id, trackingNumber) => {
    try {
      const { data } = await printAirwaybill(id, {
        isPrintAndRepack: true
      });
      downloadAsPdf(data, trackingNumber);
    } catch ({ message }) {
      notify(message);
    }
  };

  const handleSizeChange = ({ value, businessName, delivery }) => {
    if (!value) {
      return;
    }
    const weightValue = value.split('KG')[0];
    if (isNaN(weightValue)) {
      notify('Please enter number not text');
      return;
    }

    setScannedFirstMile({
      ...scannedFirstMile,
      [businessName]: {
        deliveries: [
          ...scannedFirstMile[businessName].deliveries.map((childDelivery) => {
            if (childDelivery.trackingNumber === delivery.trackingNumber) {
              childDelivery.packageWeight = parseInt(weightValue);
            }
            return childDelivery;
          })
        ]
      }
    });
  };

  return (
    <LoadingWrapper loading={isLoading}>
      <Tabs
        defaultActiveKey={selectedTab}
        key={selectedTab}
        index={selectedTab}
        className="br-packages-debrief__tabs br-packages-debrief__tabs__pickups"
        onTabClick={(key, e) => {
          setSelectedTab(key);
          setIsFilterUsed(false);
        }}
      >
        {tabs.map(
          (tab, index) =>
            tab && (
              <Tabs.TabPane tab={tab.tabName} key={index}>
                <PickupsTabActions
                  checkTrackingNumberExistance={checkTrackingNumberExistance}
                  packageSizes={allPricingPackageSize}
                  useWeights={useWeights}
                />
                <Form form={form} component={false}>
                  {selectedTab === '0' ? (
                    <>
                      <NewSwappingContainer
                        title1={`Pickups (${firstMilePickupsCount})`}
                        title2={`Received (${scannedFirstMilePickupsCount})`}
                        fmPickupsCount={firstMilePickupsCount}
                        list={firstMilePickups}
                        scannedList={scannedFirstMile}
                        buttonText={intl.formatMessage({
                          id: `hubs.packages_debrief.actions.confirm_receiving_pickups`
                        })}
                        isMissedFirstMilePickups={isMissedFirstMilePickups}
                        handleSizeChange={handleSizeChange}
                        showExtraDataInReceivedTable={true}
                        useWeights={useWeights}
                      />
                    </>
                  ) : (
                    <SwappingTables
                      title1={
                        <div className="display-flex justify-space-between">
                          {`Pickups (${
                            isFilterUsed
                              ? filterItems.length
                              : lastMilePickups.length
                          })`}
                          <div></div>
                          <BRFilter
                            title="Order Type"
                            filterItems={filterOptions}
                            onSubmitFilter={onChangeDeliveryType}
                            onResetFilter={handleFilterSearch}
                          />
                        </div>
                      }
                      title2={`Received (${scannedLastMile.length}/${
                        isFilterUsed
                          ? filterItems.length
                          : starData?.deliveries.filter((el) =>
                              LAST_MILE_PICKUPS_CODES.includes(el.state.code)
                            ).length
                      })`}
                      className2="br-receive-new-pickups__table"
                      columns={lastMilePickupsColumns}
                      recievedColumns={formatEditableColumns(
                        lastMileReceivedTableColumns
                      )}
                      list={isFilterUsed ? filterItems : lastMilePickups}
                      scannedList={scannedLastMile}
                      buttonText={intl.formatMessage({
                        id: `hubs.packages_debrief.actions.confirm_receiving_pickups`
                      })}
                      disableButton={isLoading}
                      handleConfirmReceivingOrders={
                        handleConfirmReceivingOrders
                      }
                      components={components}
                      rowClassName={(record) =>
                        record?.multiPackages &&
                        record?.scannedPackages &&
                        record.multiPackages !== record.scannedPackages?.length
                          ? 'br-hub-transfer-table__missing-scans'
                          : ''
                      }
                    />
                  )}
                </Form>
              </Tabs.TabPane>
            )
        )}
      </Tabs>
    </LoadingWrapper>
  );
};

export default injectIntl(PickupsTab);
