import { fmt } from 'IntlWrapper/IntlWrapper';

import { CUSTOMER_SUPPORT, SUPER_ADMIN } from 'common/aclMatrix';
import { STARS_RATING } from 'components/ServiceRating/ServiceRating';

export const UNDERCOVERED_ZONE = 'Uncovered Zone';

export const DELIVERY_TYPES = [
  { label: 'Deliver', value: 'SEND' },
  { label: 'RTO', value: 'RTO' },
  { label: 'Exchange', value: 'EXCHANGE' },
  { label: 'Customer Return Pick up', value: 'CUSTOMER_RETURN_PICKUP' },
  { label: 'Cash Collection', value: 'CASH_COLLECTION' },
  { label: 'Sign & Return', value: 'SIGN_AND_RETURN' }
  // { label: 'Return', value: 'Return' },
];

export const MAP_DELIVERY_TYPES = {
  SEND: 'Deliver',
  CUSTOMER_RETURN_PICKUP: 'CRP',
  CASH_COLLECTION: 'Cash Collection',
  EXCHANGE: 'Exchange',
  RTO: 'RTO',
  SIGN_AND_RETURN: 'Sign & Return'
};

export const PACKAGE_TYPE_OPTIONS = [
  { value: 'Document', label: 'Document' },
  // { value: '2', label: 'Document - Sign & Return' },
  { value: 'Bulky', label: 'Bulky' },
  { value: 'Parcel', label: 'Parcel' }
];

export const PACKAGE_TYPE_OPTIONS_FILTER = [
  {
    value: 'Document',
    label: fmt({ id: 'shipments.new_order.package_type.document' })
  },
  {
    value: 'Parcel',
    label: fmt({ id: 'shipments.new_order.package_type.parcel' })
  },
  {
    value: 'Light Bulky',
    label: fmt({ id: 'shipments.new_order.package_type.light_bulky' })
  },
  {
    value: 'Heavy Bulky',
    label: fmt({ id: 'shipments.new_order.package_type.heavy_bulky' })
  }
];

export const deliveriesStates = [
  {
    value: '10:F,E,FS',
    label: 'Created'
  },
  {
    value: '21',
    label: 'Picked up from business'
  },
  {
    value: '24',
    label: 'Received at warehouse'
  },
  {
    value: '30',
    label: 'In transit between Hubs'
  },
  {
    value: '20',
    label: 'Route assigned'
  },
  {
    value: '41:F',
    label: 'Out for delivery'
  },
  {
    value: '45:F',
    label: 'Delivered'
  },
  { value: '47', label: 'Exception' },
  {
    value: '49',

    label: 'Canceled'
  },
  {
    value: '41:R,C,E',
    label: 'Out for Return'
  },
  {
    value: '46:E',
    label: 'Exchanged & Returned'
  },
  {
    value: '46:R',
    label: 'Returned to origin'
  },
  {
    value: '46:C',
    label: 'Returned'
  },
  {
    value: '49:F,E,C,CC',
    label: 'On hold'
  },
  {
    value: '48',
    label: 'Deleted'
  },
  {
    value: '102',
    label: 'Investigation'
  },
  { value: '104', label: 'Archived' },
  { value: '100', label: 'Lost' },
  { value: '101', label: 'Damaged' },
  {
    value: '10:C',
    label: 'Requested'
  },
  {
    value: '20:C',
    label: 'Pickup route assigned'
  },
  {
    value: '22',
    label: 'Out for pickup'
  },
  {
    value: '23:C',
    label: 'Picked up'
  },
  {
    value: '23:E',
    label: 'Picked up from consignee'
  },
  {
    value: '11',
    label: 'Waiting for route'
  },
  {
    value: '40',
    label: 'Out for collection'
  },
  {
    value: '45:CC',
    label: 'Collected'
  },
  {
    value: '24.1',
    label: 'Received at warehouse - onhold'
  },
  {
    value: '42',
    label: 'Pickup Requested'
  },
  {
    value: '43:S',
    label: 'Debriefed Successfully'
  },
  {
    value: '46:S',
    label: 'Sign and return'
  },
  {
    value: '24.103,105',
    label: fmt({
      id: 'deliveries.states.awaiting_business_action'
    })
  },
  {
    value: '24.104',
    label: fmt({
      id: 'deliveries.states.received_at_warehouse_archived'
    })
  },
  {
    value: '25',
    label: fmt({ id: 'orders.order_new_statues.fulfilled' })
  },
  {
    value: '60',
    label: fmt({ id: 'orders.order_new_statues.returned_to_stock' })
  },
  {
    value: '24&5',
    label: fmt({ id: 'orders.order_new_statues.ready_to_dispatch' })
  },
  {
    value: '24&106',
    label: fmt({
      id: 'orders.order_new_statues.received_at_warehouse_rejected_return'
    })
  },
  {
    value: '105&107',
    label: fmt({
      id: 'orders.order_new_statues.rejected_return'
    })
  }
];

export const EXCEPTION_STATE = 'Exception';

export const SIGN_AND_RETURN = 'Sign & Return';

export const RECEIVED_AT_WAREHOUSE = 'Received at warehouse';

export const RETURN_TO_SHIPPER = 'Return to shipper';

export const ALLOWED_STATES_TO_RESET = [23, 45, 46, 100, 101, 102, 104];

export const CANCEL_ORDER_UPDATE_TYPE = 'createCancellationRequest';

export const CANCELLATION_REQUEST = 'CANCELLATION_REQUEST';

export const BOSTA_MATERIAL = 'FLYERS_DASHBOARD';

export const BOSTA_BUSINESS_ID = 'CxuLkRiLJLSXhpNL5';

export const ORDER_CANCELLATION_ACCESS = [SUPER_ADMIN, CUSTOMER_SUPPORT];

export const ACTION_TYPES = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  SCAN: 'SCAN',
  CONFRIMED_PRINTING_AWB: 'CONFRIMED_PRINTING_AWB',
  CHANGE_REQUEST_REJECTED: 'CHANGE_REQUEST_REJECTED',
  CHANGE_REQUEST_CREATED: 'CHANGE_REQUEST_CREATED',
  ADD_STAR_INSTRUCTION: 'ADD_STAR_INSTRUCTION',
  RESTORE: 'RESTORE',
  REJECT_RETURN: 'markAsRejectedReturnFinalState',
  PRINT_AWB: 'AWB_PRINTED',
  ESCALATE_DELIVERY_LOG: 'ESCALATE_DELIVERY_LOG',
  DELETE_DELIVERY_ESCALATION_LOG: 'DELETE_DELIVERY_ESCALATION_LOG'
};

export const ASSIGN_MANY_TO_HUB_TYPE = 'assignManyToHub';

export const DATE_RANGE_FILTERS_KEYS = [
  ['pickedUpAt', 'deliveredAt', 'canceledAt', 'createdAt'],
  ['lostAt', 'damagedAt', 'investigationAt', 'scheduledAt'],
  ['confirmedAt', 'collectedAt']
];

export const REJECTION_REASONS = {
  1: fmt({ id: 'orders.states_logs.rejection_reasons.rto' }),
  2: fmt({ id: 'orders.states_logs.rejection_reasons.delivered' }),
  3: fmt({ id: 'orders.states_logs.rejection_reasons.lost' }),
  4: fmt({ id: 'orders.states_logs.rejection_reasons.damaged' })
};

export const AWB_TYPE = {
  A4: 'A4',
  A6: 'A6'
};

export const ADDRESS_TYPES = {
  COMMERCIAL: 'COMMERCIAL'
};

export const DELIVERY_RATING_ITEMS = [
  {
    label: fmt({ id: 'pickups.pickup_details.pickup_rating.star_rate' }),
    dataIndex: 'starRating',
    type: STARS_RATING
  },
  {
    label: fmt({ id: 'pickups.pickup_details.pickup_rating.rate_comment' }),
    dataIndex: 'starComment'
  },
  {
    label: fmt({
      id: 'deliveries.delivery_details.delivery_rate.delivery_experience_rate'
    }),
    dataIndex: 'serviceRating',
    type: STARS_RATING
  },
  {
    label: fmt({ id: 'pickups.pickup_details.pickup_rating.rate_comment' }),
    dataIndex: 'serviceComment'
  }
];

export const DELIVERY_UPDATE_TYPE = {
  RECEIVE_MANY_NEW_PICKUPS: 'receiveManyNewPickups',
  RETURN: 'return'
};

export const AWB_TYPES_OPTIONS = [
  { label: 'A4', value: AWB_TYPE.A4 },
  { label: 'Zebra', value: AWB_TYPE.A6 }
];

export const PAYMENT_PROVIDERS = {
  instapay: 'InstaPay',
  flash: 'Flash'
};
